/**
 * ## Subject Icon
 * 
 * @category Component
 * @module Subject Icon 
 */
import mini from "../config/mini.json";
import { IMiniPathConfig, ISubjectIconProps } from "../types/types";
import { useAppSelector } from "../hooks/hooks";

const SubjectIcon = (props: ISubjectIconProps) => {
    const isVisible = useAppSelector((state) => state.subjecticon.isVisible);
    const config: IMiniPathConfig = mini;
    // const { debugSubject } = useAppSelector((state) => state.mini);
    const { newScaleRatio } = useAppSelector((state) => state.mini);

    const iconSourceWidth = 184;
    const iconSourceHeight = 160;

    /**
     * ### Animate Button Show/Hide
     * 
     * @param button        reference to the button
     * @param start         starting pixel position of the animation
     * @param end           ending pixel position of the animation
     */
    const animateSubjectIcon = (button: any, start: string, end: string) => {
        button.animate(
            {
                top: [start, end]
            },
            {
                easing: "ease-in-out",
                iterations: 1,
                duration: 1000,
            }
        );
        button.style.top = end;
    }

    /**
     * handle showing and hiding animation of Close Button
     */
    const subjectBadgeElement = document.getElementById("title-subject-badge");

    if (subjectBadgeElement) {
        if (isVisible && subjectBadgeElement.style.top !== "0px") {
            animateSubjectIcon(subjectBadgeElement, "-150px", "0px");
        } else if (!isVisible && subjectBadgeElement.style.top === "0px") {
            animateSubjectIcon(subjectBadgeElement, "0px", "-150px");    
        }
    }

    return (
        <div 
            id="title-subject-badge-container"
            style={{
                width: iconSourceWidth * newScaleRatio,
                height: iconSourceHeight * newScaleRatio,
                position: "absolute",
                top: 0,
                left: props.miniContentWidth - props.pathWidth,
                zIndex: 1066,
                overflow: "hidden"
            }}
            onClick={props.onClick}
        >
            <div 
                id="title-subject-badge"
                style={{
                    width: iconSourceWidth * newScaleRatio,
                    height: iconSourceHeight * newScaleRatio,
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 1066
                }}
                onClick={props.onClick}
            >
                <img 
                    // src={config.icons[debugSubject].image}
                    // alt={config.icons[debugSubject].imageAlt !== "" ? config.icons[debugSubject].imageAlt : "subject icon"}

                    src={config.icons[props.icon].image}
                    alt={config.icons[props.icon].imageAlt !== "" ? config.icons[props.icon].imageAlt : "subject icon"}
                />
            </div>
        </div>
    )
}

export default SubjectIcon;
