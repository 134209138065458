import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { createLogger } from "redux-logger";
import pauseDialogSlice from "../slices/pauseDialogSlice";
import activitySlice from "../slices/activitySlice";
import buttonSlice from "../slices/buttonSlice";
import miniSlice from '../slices/miniSlice';
import timeDisplaySlice from '../slices/timeDisplaySlice';
import landscapeOnlyDialogSlice from '../slices/landscapeOnlyDialogSlice';
import pathTitleSlice from '../slices/pathTitleSlice';
import tokenFountainSlice from '../slices/tokenFountainSlice';
import navigationSlice from '../slices/navigationSlice';
import subjectIconSlice from '../slices/subjectIconSlice';

const stage: string = typeof process.env.REACT_APP_STAGE !== "undefined" ? process.env.REACT_APP_STAGE : "local";
/**
 * #### Set Non-production Middleware 
 *   
 * Include middleware in the build for every stage excluding "prod".  
 *   
 * We can't use **process.env.NODE_ENV** because it is set to "production" if not local development.
 * We could override NODE_ENV but it appears any number of npm packages do special things when
 * NODE_ENV = "production" and we want that to occur when stages are standing alone on AWS.  
 *   
 * NOTE: make sure redux-logger **logger** is the last middleware added
 */
let optionalMiddleware: any = [];
if (stage !== "prod") {
	const logger = createLogger({
		collapsed: true
	})
	optionalMiddleware.push(logger);
}
/**
 * Initialize the Redux Store 
 */
export const store = configureStore({
	reducer: {
		pause: pauseDialogSlice,
		activity: activitySlice,
		button: buttonSlice,
        mini: miniSlice,
        time: timeDisplaySlice,
        landscape: landscapeOnlyDialogSlice,
        title: pathTitleSlice,
        token: tokenFountainSlice,
        navigation: navigationSlice,
        subjecticon: subjectIconSlice
	},
	middleware: (getDefaultMiddleware) => 
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActionPaths: [],
				ignoredPaths: []
			}
		}).concat(
			optionalMiddleware
		)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
