/**
 * ## Time Display
 * 
 * Display a minute:second count up timer to a fixed target.
 * 
 * @see {@link slices/timeDisplaySlice | Time Display Slice}
 * 
 * @returns                 Time Display Counter
 * @module Time Display
 * @category Component
 */
import { CSSProperties } from "react";
import { useAppSelector } from "../hooks/hooks";

export const padWithZero = (number: number, minLength: number): string => {
    if(isNaN(number)){
        return "00";
    }
    
    const numberString = number.toString();
    if(numberString.length >= minLength) return numberString;
    return "0".repeat(minLength - numberString.length) +  numberString;
}

const TimeDisplay = (props: CSSProperties) => {
    const seconds = useAppSelector((state) => state.time.seconds);
    const duration = useAppSelector((state) => state.time.duration);
    const displayNumberStyle = {
        width: "2ch"
    }

    return (
        <div id="video-timer" className="video-timer" style={{
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <div className="timer-inner" style={{
                backgroundColor: props.backgroundColor ? props.backgroundColor : "black",
                color: props.color ? props.color : "white",
                fontSize: 18,
                width: "auto",
                paddingLeft: 10,
                paddingRight: 10,
                height: 30,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 15
            }}>
                <span style={ displayNumberStyle }>{padWithZero(Math.trunc(seconds / 60), 2)}</span>
                <span>:</span>
                <span style={ displayNumberStyle }>{padWithZero(Math.trunc(seconds % 60), 2)}</span>
                <span>&nbsp;/&nbsp;</span>
                <span style={ displayNumberStyle }>{padWithZero(Math.trunc(duration / 60), 2)}</span>
                <span>:</span>
                <span style={ displayNumberStyle }>{padWithZero(Math.trunc(duration % 60), 2)}</span>
            </div>
        </div>
    )
}

export default TimeDisplay;
