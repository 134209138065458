/**
 * ## Video/Song Player
 *  
 * 
 * @returns HTML5 Video Player
 * @module Video & Song Player
 * @category Component
 */
import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../hooks/hooks";
import { setIsDemoTime } from "../slices/activitySlice";
import { createVideoPlayButton } from "../containers/activity/utils/activityUtils";
import { SceneType } from "../containers/PathTitle";

const DemoVideoPlayer = () => {
    const dispatch = useAppDispatch();
    const demoVideoRef = useRef<HTMLVideoElement>(null);
    const {skin} = useAppSelector((state) => state.mini);
    const youngerDemoSrc = "videos/demo-videos/Demo-Mini-Path-3-compressed.mp4";
    const olderDemoSrc = "videos/demo-videos/Demo-Mini-Path-Older-2-compressed.mp4";

    // Determine the assigned aspect ratio of the video, default to 16:9
    let openingVideoElements = {
        outerClass: "responsive-16by9-wrapper",
        innerClass: "embed-responsive-16by9"
    };

    useEffect(() => {
        if(demoVideoRef?.current){
            const demoPromise = demoVideoRef.current.play();
            if (demoPromise !== undefined) {
                demoPromise.then(_ => {
                    // Autoplay started!
                }).catch(error => {
                    // Autoplay not allowed!
                    createVideoPlayButton(document.getElementById("video-wrapper"), demoVideoRef);
                });
            }
        }
    }, []);

    const handleDemoStop = () => {
        dispatch(setIsDemoTime(false));
    }

	return (
        <div className={`clearfix ${openingVideoElements.outerClass}`} id="video-wrapper" style={{
                position: "absolute",
                background: "#00000099",
                zIndex: 1103
            }}>
            <div className={`embed-responsive ${openingVideoElements.innerClass}`}>
                <video
                    id="demo-video"
                    ref={demoVideoRef}
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "90%",
                        height: "90%",
                        backgroundColor: "none"
                    }}
                    src={(skin.scene === SceneType.Younger) ? youngerDemoSrc : olderDemoSrc}
                    onContextMenu={() => {return false;}}
                    className="embed-responsive-item"
                    // autoPlay // Let the useEffect handle the autoplay functionality
                    webkit-playsinline="true"
                    playsInline
                    onEnded={handleDemoStop}
                    onClick={handleDemoStop}
                />
            </div>
        </div>
	);
}

export default DemoVideoPlayer;
