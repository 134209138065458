/**
 * ## Activity Slice
 * 
 * @category Slice
 * @module Activity Slice
 */
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from '@reduxjs/toolkit';
import { IActivityState, IActivityPayload, IUpdatePathDataPayload } from "../types/types"

declare global {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface Window {
        [index: string]: any;
	}
}

export interface IAllProgressItem {
    nid: string,
    attempts: string;
}

export type AllProgress = Array<IAllProgressItem>;

const initialState: IActivityState = {
	isLoading: false,
	isLoaded: false,
	parent: {},
	isLoadingScripts: false,
	areAllScriptsLoaded: false,
	scriptCount: 0,
	loadedScriptCount: 0,
	json: {},
	activityId: 0,
	hasRewardCallback: false,
    isJavascriptFramework: true,
    lastInterval: 0,
    isActivityJSONFirstLoad: true,
    isFirstGameLoad: true,
    shouldShowFinalReward: true,
    isFinalRewardTime: false,
    isDemoTime: false
}

const nuke = () => {
     let destroyedCount = 0;
     const cleanDOMList = window.cleanDOM;
     const whitelist = [
        "szLogger",
        "szReport",
        "szGetGlobalPhaserGame",
        "szProfileRewards",
        "SZButtons",
        "miniObserver",
        "szIsDevelopmentHost",
        "LocalQueue",
        "SZLog",
        "szGetMicroserviceUrl",
        "szHidePhaserArrows",
        "szShowPhaserArrows",
        "cleanDOM",
        "szBlockPostRewards"
    ]
    whitelist.forEach((item: string) => {
        cleanDOMList.push(item);
    })
    
    Object.keys(window).forEach((item: string) => {
        if (cleanDOMList.indexOf(item) === -1) {
            window[item] = undefined;
            destroyedCount++;
        }
    })
    window.szLogger.log("%c Nuke Count", "background-color: yellow; color: black", destroyedCount);
}

const activitySlice = createSlice({
	name: "activity",
	initialState,
	reducers: {
		setLoading: (state) => {
			state.parent = {};
			state.isLoading = true;
			state.isLoaded = false;
		},
		loadActivity: (state, action: PayloadAction<IActivityPayload>) => {
			state.parent = action.payload;
			state.isLoading = false;
			state.isLoaded = true;
		},
		setIsLoadingScripts: (state,  action: PayloadAction<boolean>) => {
			state.isLoadingScripts = action.payload;
		},
		setAreAllScriptsLoaded: (state,  action: PayloadAction<boolean>) => {
			state.areAllScriptsLoaded = action.payload;
		},
		setScriptCount: (state, action: PayloadAction<number>) => {
			state.scriptCount = action.payload;
			state.areAllScriptsLoaded = false;
			state.isLoadingScripts = true;
		},
		incrementLoadedCount: (state) => {
			state.loadedScriptCount++;
			if (state.scriptCount > 0 && state.loadedScriptCount >= state.scriptCount) {
				state.areAllScriptsLoaded = true;
				state.isLoadingScripts = false;
			}
		},
		setJSON: (state, action: PayloadAction<any>) => {
			state.json = action.payload;
		},
        clearJSON: (state) => {
			state.json = {}
		},
		setActivityId: (state, action: PayloadAction<number>) => {
			window.szLogger.log("%c Change Activity: Activity ID = ", "background-color: orange; color: black", action.payload);
			state.activityId = action.payload
		},
		resetActivity: (state) => {
			state.isLoadingScripts = false;
			state.areAllScriptsLoaded = false;
			state.scriptCount = 0;
			state.loadedScriptCount = 0;
			state.json = {};
			state.hasRewardCallback = false;
            state.isJavascriptFramework = true;
            state.isFirstGameLoad = true;
            /**
             * -------------------------- TEARDOWN -------------------------------------------
             * Kill all intervals and timeouts that may be lingering from previous
             * Phaser and Framework
			 */
            window.szLogger.log("%c Activity Teardown -------", "background-color: yellow; color: black");

            // Clear background style observer and clear applied styles
            window.miniObserver.disconnect();
            const miniBase = document.getElementById('mini-base');
            if(miniBase){
                miniBase.style.background = "transparent";
            }

            const startingInterval = state.lastInterval + 1;
            const killId = setTimeout(() => {
                for (let i = startingInterval; i <= Number(killId); i++) {
                    clearInterval(i);
                }
            }, 1);
            state.lastInterval = Number(killId);
            window.szLogger.log("%c Interval(s) killed: ", "background-color: yellow; color: black", state.lastInterval - startingInterval);
            /**
             * Unload all howls that were created by previous acitivity to prevent them 
             * from holding contexts
			 */
            if (window.Howler && window.Howler._howls) {
                const howls = window.Howler._howls;
                window.szLogger.log("%c Howl(s) killed: ", "background-color: yellow; color: black", howls.length);
                howls.forEach((howl: any) => {
                    howl.unload();
                });
            }
            /**
             * Use Phaser destroy method to kill all allocated resources and tear 
             * down the szGame object in preparation for the next activity.
			 */
            if (typeof window.szGame !== "undefined" && typeof window.szGame.game !== "undefined") {
                window.szLogger.log("%c Tear Down Phaser", "background-color: yellow; color: black");
                window.szGame.game.paused = true;
                window.szGame.game.destroy();
            }
            
            if(document.getElementById("media-wrapper")){
                document.getElementById("media-wrapper")!.style.background = "rgb(0, 0, 0)";
            }
            
            window.szBlockPostRewards = false;
            /**
			 * Tear down activity objects from IFrame DOM
			 */
            const writablesElement = document.getElementById("writables-ui");
            if (writablesElement) {
                writablesElement.remove();
            }
            const writablesPencilElement = document.getElementById("writables-ui-button-inside");
            if (writablesPencilElement) {
                writablesPencilElement.remove();
            }
            const videoPlayerElem = document.querySelector("video#sz-video-player");
            if(videoPlayerElem){
                videoPlayerElem.remove();
            }
            const videoPlayButton = document.getElementById('sz-phaser-video-play-button')
            if(videoPlayButton){
                videoPlayButton.remove();
            }

            /**
             * clean up any dangling tokens or sparkles
             */
            const tokens = document.querySelectorAll("[id^='reward-token-']"); 
            tokens.forEach((token) => {
                token.remove();
            });

            const sparkles = document.querySelectorAll(".token-sparkle-svg");
            sparkles.forEach((sparkle) => {
                sparkle.remove();
            });
            /**
             * Destroy most of the DOM Globals created by an activity, there is a short whitelist of 
             * globals that are required to survive
             */
            nuke();
            
            window.szLogger.log("%c Activity Teardown -------", "background-color: yellow; color: black");
            /**
             * -------------------------- TEARDOWN -------------------------------------------
			 */
        },
		completeCurrentLevel: (state, action: PayloadAction<number>) => {
            state.json.data.pathData[state.parent.settings.szLearningPathData.currentLevel - 1].finished = 1;
			state.parent.settings.szLearningPathData.currentLevel = action.payload;
		},
		setCurrentLevel: (state, action: PayloadAction<number>) => {
			state.parent.settings.szLearningPathData.currentLevel = action.payload as number;
		},
        setTotalTokenBalance: (state, action: PayloadAction<number>) => {
            state.parent.settings.sz_profiles.szProfileRewardsTotal = state.parent.settings.sz_profiles.szProfileRewardsTotal + action.payload;
        },
		setRewardsCallback: (state,  action: PayloadAction<boolean>) => {
			state.hasRewardCallback = action.payload;
		},
        setIsJavascriptFramework: (state, action: PayloadAction<boolean>) => {
            state.isJavascriptFramework = action.payload;
        },
        setIsActivityJSONFirstLoad: (state, action: PayloadAction<boolean>) => {
            state.isActivityJSONFirstLoad = action.payload;
        },
        setIsFirstGameLoad: (state, action: PayloadAction<boolean>) => {
            state.isFirstGameLoad = action.payload;
        },
        setShouldShowFinalReward: (state, action: PayloadAction<boolean>) => {
            state.shouldShowFinalReward = action.payload;
        },
        setIsFinalRewardTime: (state, action: PayloadAction<boolean>) => {
            state.isFinalRewardTime = action.payload;
        },
        setIsDemoTime: (state, action: PayloadAction<boolean>) => {
            state.isDemoTime = action.payload;
        },
        updatePathData: (state, action: PayloadAction<IUpdatePathDataPayload>) => {
            state.parent.settings.szLearningPathData.activities[action.payload.id][0].attempts = Number(action.payload.attempts);
            state.parent.settings.szLearningPathData.activities[action.payload.id][0].confirmed = Number(action.payload.confirmed);
            if (state.parent.settings.szLearningPathData.activities[action.payload.id][0].finished === 0) {
                state.parent.settings.szLearningPathData.activities[action.payload.id][0].finished = Number(action.payload.finished);
            }
            state.parent.settings.szLearningPathData.activities[action.payload.id][0].successful_attempts = Number(action.payload.successful_attempts);
            state.parent.settings.szLearningPathData.activities[action.payload.id][0].last_access = action.payload.last_access;
        },
        updatePathDataProgress: (state, action: PayloadAction<AllProgress>) => {
            if (action.payload && action.payload.length > 0) {
                action.payload.forEach((progress: IAllProgressItem) => {
                    Object.keys(state.parent.settings.szLearningPathData.activities).forEach((key: any) => {
                        if (state.parent.settings.szLearningPathData.activities[key][0].nid === progress.nid) {
                            state.parent.settings.szLearningPathData.activities[key][0].attempts = progress.attempts;
                        }
                    })
                })
            }
        }
	}
});

export const {
	loadActivity,
	setLoading,
	setScriptCount,
	incrementLoadedCount,
	setIsLoadingScripts,
	setAreAllScriptsLoaded,
	setJSON,
    clearJSON,
	setActivityId,
	resetActivity,
	completeCurrentLevel,
	setCurrentLevel,
    setTotalTokenBalance,
	setRewardsCallback,
    setIsJavascriptFramework,
    setIsActivityJSONFirstLoad,
    setIsFirstGameLoad,
    setShouldShowFinalReward,
    setIsFinalRewardTime,
    setIsDemoTime,
    updatePathData,
    updatePathDataProgress
} = activitySlice.actions;

export default activitySlice.reducer;
