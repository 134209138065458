/**
 * ## Subject Icon Slice
 * 
 * @category Slice
 * @module Subject Icon Slice
 */
import { createSlice } from "@reduxjs/toolkit";

interface ISubjectIconState {
    isVisible: boolean;
}

const initialState: ISubjectIconState = {
    isVisible: true,
}

const SubjectIconSlice = createSlice({
	name: "subjecticon",
	initialState,
	reducers: {
        hideSubjectIcon: (state) => {
            state.isVisible = false;
        },
        showSubjectIcon: (state) => {
            state.isVisible = true;
        }
	}
});

export const { hideSubjectIcon, showSubjectIcon } = SubjectIconSlice.actions;

export default SubjectIconSlice.reducer;
