/**
 * ## SZ Game Buttons
 * 
 * Implementation of the HTML overlay buttons that are present for all AT activities.  These  
 * buttons provide the interface between the DOM and the activity, calling into methods  
 * that are present on the global DOM.  Button groupings have some unique combinations based on  
 * the activity type.  
 *   
 * These are combined with the overide of the **SZButtons** class to provide two-way control of  
 * the DOM and activities.  
 *   
 * @category Component
 * @module Game Buttons
 */
import { togglePause, toggleIsMusicPlaying, toggleIsSpeakerPlaying } from "../slices/pauseDialogSlice";
import { useAppSelector, useAppDispatch } from "../hooks/hooks";
import TimeDisplay from "./TimeDisplay";
import { ISZGameButtons } from "../types/types";
import { ActivityType } from "./ActivityUI";

declare global {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface Window {
        pauseGame: any;
        szGame: any;
        szToggleHome: any;
        szPlayAudio: any;
        szGetSpeakerButton: any;
        szPhaserNavLeft: any;
        szPhaserNavRight: any;
	}
}

const SZGameButtons = (props: ISZGameButtons) => {
    const isPaused = useAppSelector((state) => state.pause.isPaused);
    const isMusicPlaying = useAppSelector((state) => state.pause.isMusicPlaying);
    let isSpeakerPlaying = useAppSelector((state) => state.pause.isSpeakerPlaying);
    const pathBaseWidth = useAppSelector((state) => state.mini.pathBaseWidth);
    const activityWidth = useAppSelector((state) => state.mini.activityWidth);
    const dispatch = useAppDispatch();

    /**
     * Framework hook to indicate state of the speaker button
     * which is called by the Flash Card Engine
     * 
     * @returns                 current state of the speaker button
     */
    window.szGetSpeakerButton = () => {
        return isSpeakerPlaying;
    };

    const handleTogglePause = () => {
        dispatch(togglePause());
        if (typeof window.pauseGame === 'function' && !isPaused) {
            window.pauseGame();
        }
    }

    const handleVideoTogglePause = () => {
        const mediaPlayer: HTMLVideoElement = document.getElementById('sz-media-player') as HTMLVideoElement;
        if (!isPaused) {
            mediaPlayer.pause();
        } else {
            mediaPlayer.play();
        }
        dispatch(togglePause());
    }

    const handleToggleMusic = () => {
        dispatch(toggleIsMusicPlaying());
        window.szGame?.game?.state?.states[window.szGame.game.state.current].toggleMusicSelected();
    }

    const handleVideoToggleMusic = () => {
        dispatch(toggleIsMusicPlaying());
        const mediaPlayer: HTMLVideoElement = document.getElementById('sz-media-player') as HTMLVideoElement;
        mediaPlayer.muted = isMusicPlaying;
    }

    const handleToggleSpeaker = () => {
        dispatch(toggleIsSpeakerPlaying());
        isSpeakerPlaying = !isSpeakerPlaying;
        if (typeof window.szPlayAudio === "function") {
                window.szPlayAudio();
        }
    }

    const handleHelp = () => {
        window.szGame?.game?.state?.states[window.szGame.game.state.current].helpSelected();
    }

    const handleToggleHome = () => {
        if (typeof window.szToggleHome === "function") {
            window.szToggleHome();
        }
    }

    const handleNavLeft = () => {
        if (typeof window.szPhaserNavLeft === "function") {
            window.szPhaserNavLeft();
        }
    }

    const handleNavRight = () => {
        if (typeof window.szPhaserNavRight === "function") {
            window.szPhaserNavRight();
        }
    }

    switch (props.activityType) {
        case ActivityType.Phaser:
        case ActivityType.InteractiveWorksheet:
            return (
                <div id="sz-game-inside-buttons" className="sz-game-buttons">
                    <button id="game-toggle-tray-button" className="btn game-toggle-tray-button"><i className="fa fa-question" /></button>
                    <button className={`btn game-music-button btn-green ${ !isMusicPlaying ? "sz-music-off" : "" } />`} onClick={handleToggleMusic}><i className="fa fa-music" /></button>
                    <button className="btn game-help-button btn-light-blue" onClick={handleHelp}><i className="fa fa-question" /></button>
                    <button className="btn game-pause-button btn-blue" onClick={handleTogglePause}>{ isPaused ? <i className="fa fa-play" /> : <i className="fa fa-pause" /> }</button>
                </div>
            )
        case ActivityType.Book:
            return (
                <>
                    <div id="sz-game-inside-buttons" className="sz-game-buttons">
                        <button id="game-toggle-tray-button" className="btn game-toggle-tray-button"><i className="fa fa-question" /></button>
                        <button className="btn game-pause-button btn-blue" onClick={handleTogglePause}>{ isPaused ? <i className="fa fa-play" /> : <i className="fa fa-pause" /> }</button>
                        <button className="btn game-help-button btn-light-blue" onClick={handleToggleHome}><i className="fa fa-home" /></button>
                    </div>
                    <button
                        className="phaser-arrow-button phaser-arrow-button-left phaser-arrows-hidden"
                        id="phaser-arrow-button-left"
                        onClick={handleNavLeft}
                        style={{
                            left: Math.trunc(pathBaseWidth + (activityWidth * 0.01))
                        }}
                    >
                        <img src="https://anywhereteacher.com/sites/default/files/icons/carousel-arrow-left-blue.png" alt="Left Arrow" />
                    </button>
                    <button className="phaser-arrow-button phaser-arrow-button-right phaser-arrow-button-right phaser-arrows-hidden" id="phaser-arrow-button-right"  onClick={handleNavRight} >
                        <img src="https://anywhereteacher.com/sites/default/files/icons/carousel-arrow-right-blue.png" alt="Right Arrow" />
                    </button>
                </>

            )
        case ActivityType.FlashCardsPhaser:
            return (
                <>
                    <div id="sz-game-inside-buttons" className="sz-game-buttons">
                        <button id="game-toggle-tray-button" className="btn game-toggle-tray-button"><i className="fa fa-question" /></button>
                        <button className={`btn game-music-button btn-green ${ !isMusicPlaying ? "sz-music-off" : "" } />`} onClick={handleToggleMusic}><i className="fa fa-music" /></button>
                        <button className="btn game-help-button btn-light-blue" onClick={handleHelp}><i className="fa fa-question" /></button>
                        <button className="btn game-pause-button btn-blue" onClick={handleTogglePause}>{ isPaused ? <i className="fa fa-play" /> : <i className="fa fa-pause" /> }</button>
                        <button className="btn game-home-button btn-orange" onClick={handleToggleHome}><i className="fa fa-home" /></button>
                        <button className="btn game-speaker-button btn-purple" onClick={handleToggleSpeaker}>{ isSpeakerPlaying ? <i className="fa fa-volume-up" /> : <i className="fa fa-volume-off" />}</button>
                    </div>
                    <button
                        className="phaser-arrow-button phaser-arrow-button-left phaser-arrows-hidden"
                        id="phaser-arrow-button-left"
                        onClick={handleNavLeft}
                        style={{
                            left: Math.trunc(pathBaseWidth + (activityWidth * 0.01))
                        }}
                    >
                        <img src="https://anywhereteacher.com/sites/default/files/icons/carousel-arrow-left-blue.png" alt="Left Arrow" />
                    </button>
                    <button 
                        className="phaser-arrow-button phaser-arrow-button-right phaser-arrow-button-right phaser-arrows-hidden" 
                        id="phaser-arrow-button-right"  
                        onClick={handleNavRight}
                        style={{
                        
                        }}
                    >
                        <img src="https://anywhereteacher.com/sites/default/files/icons/carousel-arrow-right-blue.png" alt="Right Arrow" />
                    </button>
                </>
            )
        case ActivityType.Video:
        case ActivityType.Song:
            return (
                <>
                    <div id="sz-game-inside-buttons" className="sz-game-buttons">
                        <button id="game-toggle-tray-button" className="btn game-toggle-tray-button"><i className="fa fa-question" /></button>
                        <button className={`btn game-music-button btn-green ${ !isMusicPlaying ? "sz-music-off" : "" } />`} onClick={handleVideoToggleMusic}><i className="fa fa-music" /></button>
                        <button className="btn game-pause-button btn-blue" onClick={handleVideoTogglePause}>{ isPaused ? <i className="fa fa-play" /> : <i className="fa fa-pause" /> }</button>
                    </div>

                    <TimeDisplay backgroundColor="black" color="white" />
                </>
            )
        default:
            return null;
    }
}

export default SZGameButtons;

