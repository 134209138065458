/**
 * ## Path Title Slice
 * 
 * @category Slice
 * @module Path Title Slice
 */
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from '@reduxjs/toolkit';

interface IPathTitleState {
	isOpen: boolean;
}

const initialState: IPathTitleState = {
	isOpen: false,
}

const pathTitleSlice = createSlice({
	name: "title",
	initialState,
	reducers: {
		setIsOpen: (state, action: PayloadAction<boolean>) => {
			if(state.isOpen !== action.payload){
				state.isOpen = action.payload;
			}
		}
	}
});

export const { setIsOpen } = pathTitleSlice.actions;

export default pathTitleSlice.reducer;
