/**
 * ## URL Helpers and Assorted Tools
 * 
 * @category Helpers
 * @module URL Helper Tools
 */

/**
 * ## Map URL from IFrame URL to Hosting URL
 * 
 * used to support multiple testing and deployment environments.
 * 
 * Because the mini path iFrame is a unique, URL calls to global AT resource must  
 * be made to the **parent** URL.
 * 
 * | IFrame | Parent Environment | Protocol |  
 * | --- | --- | --- |  
 * | localhost:3003 | localhost:8082 | http |  
 * | dev.mini.anywhereteacher.com | beta.anywhereteacher.com | https |  
 * | mini.anywhereteacher.com | anywhereteacher.com | https |  
 * 
 * @returns         Hosting URL that is paired with the IFrame URL
 */
export const mapUrl = (): string => {
    let fetchUrl = "";
    if (window.location.href.match(/^((http):\/)\/(localhost:3003).*$/)) {
        fetchUrl = "http://localhost:8082"
    } else if (window.location.href.match(/^((https):\/)\/(dev.mini).*$/)) {
        fetchUrl = "https://beta.anywhereteacher.com"
    } else if (window.location.href.match(/^((https):\/)\/(mini.).*$/)) {
        fetchUrl = "https://anywhereteacher.com"
    }
        
    return fetchUrl;
}

/**
 * ## Determine if browser is Apple Safari
 * 
 * @returns                 true if detects **Safari**, false if not
 */
export const isAppleSafari = () => {
    const platformExpression = /Mac|iPhone|iPod|iPad/i;
    const rejectedExpression = /Chrome|Android|CriOS|FxiOS|EdgiOS/i;
    const expectedExpression = /Safari/i;

    const agent = navigator.userAgent;
    if (rejectedExpression.test(agent)) {
        return false;
    }
    return platformExpression.test(agent) && expectedExpression.test(agent);
};
/**
 * ## Determine if iOS device
 * 
 * @returns                 true if detects **iOS**, false if not
 */
export const isAppleIOS = () => {
    const platformExpression = /iPhone|iPod|iPad/i;
    const rejectedExpression = /Android/i;

    const agent = navigator.userAgent;
    if (rejectedExpression.test(agent)) {
        return false;
    }
    return platformExpression.test(agent);
};