/**
 * ## Landscape Only Indicator  
 *   
 * Show the wiggling tablet indicator.  With mini paths portrait orientation is disallowed  
 *   
 * @see {@link slices/landscapeOnlyDialogSlice | Landscape Only Slice}  
 *  
 * @category Component  
 * @module Landscape Only Indicator  
 */
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useAppSelector } from "../hooks/hooks"

const LandscapeOnlyDialog = () => {
	let isPortrait = useAppSelector((state) => state.landscape.isPortrait);
    const dialogWidth = 400;
    const dialogHeight = 400;

    if (isPortrait) {   
        return (
            <Dialog
                open={isPortrait}
                maxWidth={false}
                PaperProps={
                    { style: { 
                            borderRadius: 20
                        },
                        sx: { 
                            position: "fixed",
                            m: 0
                        }
                    }
                }
            >        
                <DialogContent style={{width: dialogWidth, height: dialogHeight, padding: 0}}>
                    <div 
                        id="force-orientation-landscape" 
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            height: "100%",
                            background: "rgba(255,255,255,.9)",
                            zIndex: 3,
                            borderRadius: 8,
                            overflow: "hidden"
                        }}
                    >
                        <img style={{position: "absolute", top: 0, left: 0}} src="images/arrows-right.png" alt="force to use landscape mode" />

                        <div id="force-orientation-inner" style={{
                                width:"90%",
                                height: "90%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                        >
                            <div id="force-orientation-image" style={{
                                    width: "75%",
                                    height: "75%"
                                }}
                            >
                                <img src="images/device.png" id="force-orientation-device" alt="force to use landscape mode" />
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    } else {
        return null;
    }
}

export default LandscapeOnlyDialog;
