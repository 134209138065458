/**
 * Sentry Reporting Helpers
 * 
 * @category Helpers
 * @module Sentry Reporting
 */
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Extras } from "@sentry/types"
import { ErrorInfo } from "react";
import config from "../config/config";

const isLocal = process.env.NODE_ENV === "development";
const stage: string = typeof process.env.REACT_APP_STAGE !== "undefined" ? process.env.REACT_APP_STAGE : "local";
/**
 * #### Initialize Sentry for App Monitoring  
 * 
 * Each stage has it's own Sentry project and therefore a specific DSN, based on the stage
 * we will attach the correct DSN from the config file (config/config.ts).  
 * 
 * When developing in the local environment we will not init Sentry or send messages
 * to it, use the local terminal and browser developer tools.  
 *
 * @export
 */
export function initSentry() {
	if (isLocal) {
	  	return;
	}
	Sentry.init({
		dsn: config.SENTRY_DSN[stage],
		integrations: [
			new Integrations.BrowserTracing(),
			Sentry.httpClientIntegration()
		],
		environment: stage !== "prod" ? "development" : "production",
		/**
		 * Set tracesSampleRate to 1.0 to capture 100%
		 * of transactions for performance monitoring.
		 * We recommend adjusting this value in production
		 */
		tracesSampleRate: 1.0,
	});
}
/**
 * #### Log an Error to Sentry
 * 
 * **DO NOT** log errors to Sentry when local environment
 *
 * @export
 * @param error				error object
 * @param errorInfo			extra info to be associated to the log mnessage 
 */
export function logError(error: any, errorInfo: ErrorInfo) {
	if (isLocal) {
	  	return;
	}

	Sentry.withScope(
		(scope: Sentry.Scope) => {
			errorInfo && scope.setExtras(errorInfo as unknown as Extras);
			Sentry.captureException(error);
		}
	);
}
/**
 * #### onError method primarily for use in Try/Catch blocks
 * 
 * **Auth Errors**: If not a JS Error object and there is a message then move the passed
 * error object into the extra info, pull the message from the extra info and create 
 * a JS Error object using that message and then log it.
 * 
 * **API Errors**: If the error object has a config property and in that property a URL exists add that
 * URL to the extra info object and then log it
 * 
 * Otherwise, just log the error straight with an empty extra info object.
 *
 * @export
 * @param error				error object/extra info object
 */
export function onError(error: any) {
	let errorInfo: ErrorInfo = {
		componentStack: ""
	};
	let message = error.toString();
	
	// Auth Errors
	if (!(error instanceof Error) && error.message) {
		errorInfo = error;
		message = error.message;
		error = new Error(message);
	}
  
	logError(error, errorInfo);
  
	console.error(message);
}
