/**
 * ## Avatar Modal Component
 * 
 * Provide the token fountain target display where the flying tokens land, it includes:
 * + Current avatar
 * + Profile name
 * + Token image and token balance
 * 
 * @category Component
 * @module Avatar Modal
 */
import { useAppSelector } from "../hooks/hooks";
import Typography from "@mui/material/Typography";

const Avatar = () => {
    const activeProfile = useAppSelector((state) => state.activity.parent.settings.sz_profiles.szPhaserProfile.active);
    const rewardsTotal = useAppSelector((state) => state.activity.parent.settings.sz_profiles.szProfileRewardsTotal);

    return(
        <div 
            id="avatar-container"
            style = {{
                display: "flex",
                overflow: "auto",
                whiteSpace: "nowrap",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <img
                src={activeProfile.avatar}
                alt="selected avatar portrait"
                style={{
                    borderRadius: "50%",
                    padding: "5px",
                    backgroundColor: "white",
                    margin: 20
                }}
            />
            <Typography variant="h4" component="h4" style={{
                fontFamily: "kg_blank_space_solid",
                fontSize: 24,
                paddingRight: 30
            }}>
                {activeProfile.name}
            </Typography>
            <img
                id="dialog-token"
                src="https://anywhereteacher.com/sites/default/files/coins/at-alt-logo-coin-96-fs8.png"
                alt="anywhere teacher token"
                style={{
                    width: 60,
                    height: 60,
                }}
            />
            
            <Typography id="total-tokens" variant="h4" component="h4" style={{
                fontFamily: "kg_blank_space_solid",
                fontSize: 24,
                paddingLeft: 5,
                paddingRight: 20
            }}>
                {rewardsTotal}
            </Typography>
        </div>
    )

}

export default Avatar;
