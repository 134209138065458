/**
 * ## Video/Song Player
 *  
 * 
 * @returns HTML5 Video Player
 * @module Video & Song Player
 * @category Component
 */
import { useAppDispatch } from "../hooks/hooks";
import { setIsButtonBlocked } from "../slices/buttonSlice";
import { setOpen } from "../slices/pauseDialogSlice";
import { setTimeDuration, setTimeSeconds, setVideoHasPlayed } from "../slices/timeDisplaySlice";
import { IVideoSongPlayerProps } from "../types/types"
import { sendMessage } from "../lib/iFrameCommunications";
import { useEffect, useRef } from "react";
import { createVideoPlayButton } from "../containers/activity/utils/activityUtils";

const FinalRewardVideoPlayer = (props: IVideoSongPlayerProps) => {
    const dispatch = useAppDispatch();
    const finalVideoRef = useRef<HTMLVideoElement>(null);
    const videos = props.videos;
    const node = props.node;
	const videoBackgroundColor = node.field_background_color?.und?.length > 0 ? node.field_background_color.und[0].rgb : "#000000";
    
    // Set the background color in the media-wrapper so the mutation observer will pick it up and move it to the body
    if (document.getElementById("media-wrapper")) {
        document.getElementById("media-wrapper")!.style.backgroundColor = videoBackgroundColor;
    }
    document.body.style.backgroundColor = videoBackgroundColor;

    useEffect(() => {
        if(finalVideoRef?.current){
            const playPromise = finalVideoRef.current.play();
            if (playPromise !== undefined) {
                playPromise.then(_ => {
                    // Autoplay started!
                }).catch(error => {
                    // Autoplay not allowed!
                    createVideoPlayButton(document.getElementById("video-wrapper"), finalVideoRef);
                });
            }
        }
    }, []);

    /**
     * determine the assigned aspect ratio of the video, default to 16:9
     */
    const aspectRatio = node.field_video_aspect_ratio?.und?.length > 0 ? node.field_video_aspect_ratio.und[0].value : "16:9";
    let openingVideoElements = {
        outerClass: "",
        innerClass: ""
    };

    if (aspectRatio === '4:3') {
        openingVideoElements.outerClass = "responsive-4by3-wrapper";
        openingVideoElements.innerClass = "embed-responsive-4by3";            
    } else if (aspectRatio === '3:4') {
        openingVideoElements.outerClass = "responsive-3by4-wrapper";
        openingVideoElements.innerClass = "embed-responsive-3by4";            
    } else if (aspectRatio === '722:1000') {
        openingVideoElements.outerClass = "responsive-722by1000-wrapper";
        openingVideoElements.innerClass = "embed-responsive-722by1000";            
    } else {
        openingVideoElements.outerClass = "responsive-16by9-wrapper";
        openingVideoElements.innerClass = "embed-responsive-16by9";            
    }
    /**
     * ### onPlay Event Handler  
     * 
     * @param event
     */
    const onPlay = (event: any) => {
        dispatch(setVideoHasPlayed(true));
        dispatch(setTimeDuration(event.target.duration));
        dispatch(setIsButtonBlocked(false)) ;
        dispatch(setOpen(false));
    }
    
    /**
     * 
     * @param event 
     */
    const onPause = (event: any) => {

    }
    
    /**
     * 
     * @param event 
     */
    const onTimeUpdate = (event: any) => {
        const currentTime = event.target.currentTime;
        dispatch(setTimeSeconds(currentTime));
    }

    let finalURI = "";

    /**
     * 
     * @param event 
     */
    const onDurationChange = (event: any) => {
        if (event.target.currentTime > 0) {
            dispatch(setVideoHasPlayed(true));
        } else {
            dispatch(setVideoHasPlayed(false));
            dispatch(setOpen(true))
            setTimeout(() => {
                if (event.target.currentTime <= 0) {
                    event.target.poster = finalURI;
                }
            }, 500)
        }
    }
    
    /**
     * 
     */
    const onEnded = () => {
        if (document.getElementById("media-wrapper")) {
            document.getElementById("media-wrapper")!.style.backgroundColor = "#000000";
        }

        sendMessage("CLOSE MINI");
    }
    /**
     *
     *
     */
	return (
        <div className={`clearfix ${openingVideoElements.outerClass}`} id="video-wrapper" style={{
                position: "absolute",
                background: "transparent"
            }}>
            <div className={`embed-responsive ${openingVideoElements.innerClass}`}>
                <video
                    id="final-video"
                    className="embed-responsive-item"
                    ref={finalVideoRef}
                    style={{backgroundColor: videoBackgroundColor}}
                    src={videos[0]}
                    onContextMenu={() => {return false;}}
                    // autoPlay // Let the useEffect handle the autoplay functionality
                    webkit-playsinline="true"
                    playsInline
                    onDurationChange={(event) => { onDurationChange(event) }}
                    onPlay={(event) => { onPlay(event) }}
                    onTimeUpdate={(event) => { onTimeUpdate(event) }}
                    onPause={(event) => { onPause(event) }}
                    onEnded={() => { onEnded() }}
                />
            </div>
        </div>
	);
}

export default FinalRewardVideoPlayer;
