/**
 * ## Time Display Slice
 * 
 * @category Slice
 * @module Time Display Slice
 */
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from '@reduxjs/toolkit';

interface ITimeDisplayState {
    seconds: number;
    duration: number;
    complete: boolean;
    hasPlayed: boolean;
}

const initialState: ITimeDisplayState = {
	seconds: 0,
    duration: 0,
    complete: false,
    hasPlayed: false
}

const timeDisplaySlice = createSlice({
	name: "time",
	initialState,
	reducers: {
		setTimeSeconds: (state, action: PayloadAction<number>) => {
            state.hasPlayed = true;
			state.seconds = action.payload;
            if (action.payload > state.duration * 0.75) {
                state.complete = true;
            }
		},
		setTimeDuration: (state, action: PayloadAction<number>) => {
			state.duration = action.payload;
		},
        setVideoHasPlayed: (state, action: PayloadAction<boolean>) => {
            state.hasPlayed = action.payload;
        }
	}
});

export const { setTimeSeconds, setTimeDuration, setVideoHasPlayed } = timeDisplaySlice.actions;

export default timeDisplaySlice.reducer;
