/**
 * ## Pause Dialog Modal
 * 
 * Control the Pause Dialog with the Reset and COntinue Activity buttons.
 * 
 * @category Container
 * @module Pause Dialog
 */
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { setOpen, setIsMusicPlaying } from "../slices/pauseDialogSlice";
import { useAppSelector, useAppDispatch } from "../hooks/hooks"
import PauseDialogButton from "../components/PauseDialogButton";
import { ActivityType } from "../components/ActivityUI";

declare global {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface Window {
        resumeGame: any;
        restartGame: any;
	}
}

const PauseDialog = () => {
	const activityJSON = useAppSelector((state) => state.activity.json);
	const isPaused = useAppSelector((state) => state.pause.isPaused);
	const dispatch = useAppDispatch();

    const dockButtons = () => {
        window.szGame?.game?.szButtons?.hide();
    }

	const handlePlay = () => {
        if (activityJSON.data.node.type === ActivityType.Video || activityJSON.data.node.type === ActivityType.Song) {
            dispatch(setOpen(false));
            const mediaPlayer: HTMLVideoElement = document.getElementById("sz-media-player") as HTMLVideoElement;
            mediaPlayer.play();
        } else {
            dispatch(setOpen(false));
            dockButtons();

            if (typeof window.resumeGame === "function") {
                window.resumeGame();
            }
        }
	};

	const handleRestart = () => {
        if (activityJSON.data.node.type === ActivityType.Video || activityJSON.data.node.type === ActivityType.Song) {
            /**
             * Video/Song restart procedure
             */
            dispatch(setOpen(false));
            const mediaPlayer: HTMLVideoElement = document.getElementById("sz-media-player") as HTMLVideoElement;
            /**
             * on Restart make sure video is no longer muted
             */
            dispatch(setIsMusicPlaying(true));
            mediaPlayer.muted = false;
            /**
             * on Restart reset player to start at beginning
             */
            mediaPlayer.pause();
            mediaPlayer.currentTime = 0;
            mediaPlayer.play();
        } else {
            /**
             * Standard restart procedure
             */
            dispatch(setOpen(false));
            dockButtons();
    
            if (typeof window.restartGame === "function" && typeof window.resumeGame === "function") {
                window.restartGame();
                window.resumeGame();
            }
        }
	};
    /**
     * to contain the modal and have it center in the media-wrapper we must set the
     * container to element, importantly you must also style with position: "absolute"
     * 
     * ! Additionally, if you want to contain the backdrop to the element you must set the slot.props for 
     * ! the backdrop to position: "absolute".  If you want the backdrop to cover the document.body 
     * ! don't style the slotProps which is the default container.
     */
	return (
		<React.Fragment>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Dialog
                    open={isPaused}
                    onClose={handlePlay}
                    maxWidth={false}
                    style={{ position: "absolute" }}
                    PaperProps={{
                        style: { borderRadius: 40 }
                    }}
                >
                    <DialogActions className="phaser-content-pause-dialog">
                        <PauseDialogButton
                        direction="left"
                        handler={handleRestart}
                        image="images/up-next-dialog-play-again-button-216-fs8.png"
                        text="Play Again"
                        />
                        <PauseDialogButton
                        direction="right"
                        handler={handlePlay}
                        image="images/up-next-dialog-continue-button-216-fs8.png"
                        text="Continue"
                        />
                    </DialogActions>
                </Dialog>
            </div>
        </React.Fragment>
	);
}

export default PauseDialog;
