/**
 * ## Restart Path Dialog Modal
 * 
 * @category Container
 * @module Restart Path Dialog
 */
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { useAppSelector, useAppDispatch } from "../hooks/hooks"
import PauseDialogButton from "../components/PauseDialogButton";
import { ActivityType } from "../components/ActivityUI";
import { DialogTitle } from "@mui/material";
import { mapUrl } from "../lib/urlTools";
import { setRestartDialogOpen } from "../slices/miniSlice";
import { sendMessage } from "../lib/iFrameCommunications";

const RestartPathDialog = () => {
	const activityJSON = useAppSelector((state) => state.activity.json);
	const isPaused = true;
	const dispatch = useAppDispatch();
    let parentData = useAppSelector((state) => state.activity.parent);

	const handleRestart = () => {
        let pathId = parentData.settings.szLearningPathId;
        let uid = parentData.settings.sz_profiles.szPhaserProfile.uid;
        let pid = parentData.settings.sz_profiles.szPhaserProfile.active.pid;    
        let resetPath = mapUrl() + "/adventure/reset/" + pathId + "/" + uid + "/" + pid;

        // Asynchronously reset the path
        fetch (resetPath)
            .then(response => response.json())
            .then(json => {
                window.szLogger.log("%c Path Reset Fetch Complete:", "background-color: pink; color: black", json);
                sendMessage("REFRESH MINI");
            })
            .catch(error => {
                window.szLogger.log("Error: Path Reset Fetch Error", error);
            });
	};

	const handleResume = (event: Object, reason: string) => {
        if(reason !== "backdropClick"){
            if (activityJSON.data.node.type === ActivityType.Video || activityJSON.data.node.type === ActivityType.Song) {
                dispatch(setRestartDialogOpen(false));
                const mediaPlayer: HTMLVideoElement = document.getElementById("sz-media-player") as HTMLVideoElement;
                mediaPlayer.play();
            } else {
                dispatch(setRestartDialogOpen(false));
            }
        }
	};

    /**
     * to contain the modal and have it center in the media-wrapper we must set the
     * container to element, importantly you must also style with position: "absolute"
     * 
     * ! Additionally, if you want to contain the backdrop to the element you must set the slot.props for 
     * ! the backdrop to position: "absolute".  If you want the backdrop to cover the document.body 
     * ! don't style the slotProps which is the default container.
     */
	return (
		<React.Fragment>
            <div id="restart-dialog-shadow" style={{
                position: "absolute",
                background: "#ff000099",
                zIndex: 1103
            }}>
                <div id="dialog-wrapper" style={{ display: 'flex', alignItems: 'center' }}>
                    <Dialog
                        open={isPaused}
                        onClose={handleResume}
                        maxWidth={false}
                        style={{ position: "absolute" }}
                        PaperProps={{
                            style: { borderRadius: 40 }
                        }}
                    >
                        <DialogTitle style={{ 
                            textAlign: 'center', 
                            fontFamily: "kg_blank_space_solid", 
                            color: "#1b6eb7", 
                            fontSize: "40px",
                            paddingBottom: "0px"
                        }}>
                            Restart this path?
                        </DialogTitle>
                        <DialogActions 
                            className="phaser-content-pause-dialog" 
                        >
                            <PauseDialogButton
                            direction="left"
                            handler={handleRestart}
                            image="images/up-next-dialog-play-again-button-216-fs8.png"
                            text="Restart"
                            />
                            <PauseDialogButton
                            direction="right"
                            handler={handleResume}
                            image="images/up-next-dialog-continue-button-216-fs8.png"
                            text="Continue"
                            />
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </React.Fragment>
	);
}

export default RestartPathDialog;
