/**
 * ## Mini Component
 * 
 * The Mini component establishes communication between the parent frame and the child iframe so
 * that the parent's environment can be ertrieved and lifted into the child's environment.
 * 
 * The window.postMessage method is used because browser's do not allow direct access from a child iframe to its 
 * parent (unless they are on exactly the same domain).
 * [MDN postMessage Documentation](https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage)
 * 
 * @category Route
 * @module Mini Path Route
 */
import { useCallback, useEffect, useRef, useState } from "react";

import PauseDialog from "../containers/PauseDialog";
import TokenFountain from "../containers/TokenFountain";
import Activity from "../containers/activity/Activity";
import PathTitle, { CharacterType, SceneType } from "../containers/PathTitle";
import { setOpen } from "../slices/pauseDialogSlice"
import { loadActivity, setLoading, setActivityId, setShouldShowFinalReward, setIsActivityJSONFirstLoad } from "../slices/activitySlice";
import { setIsButtonBlocked } from "../slices/buttonSlice";
// import { setDebugSubject, setDebugCharacter, setDebugScene } from "../slices/miniSlice";
import { setDoesNeedRendering, setScreenSize, setSkin, showGameButtons, setAreGameButtonsCollapsed, setRestartDialogOpen, setIsPathComplete} from "../slices/miniSlice";
import { setIsPortrait } from "../slices/landscapeOnlyDialogSlice";
import { setAnchorLevelByCurrentLevel } from "../slices/navigationSlice";
import { setIsOpen } from "../slices/pathTitleSlice";
import { useAppDispatch, useAppSelector } from "../hooks/hooks";
import { sendMessage } from "../lib/iFrameCommunications";
import { isAppleSafari, isAppleIOS } from "../lib/urlTools";
import { IMiniPathConfig, IMiniPathConfigEntry } from "../types/types"
import miniPathConfig from "../config/mini.json";
import { ActivityType } from "../components/ActivityUI";
import SZGameButtons from "../components/SZGameButtons";
import { ISZButtonSettings } from "../containers/activity/utils/activityUtils";
import FinalRewardVideoPlayer from "../components/FinalRewardVideoPlayer";
import DemoVideoPlayer from "../components/DemoVideoPlayer";
import RestartPathDialog from "../containers/RestartPathDialog";

const Mini = () => {
	const dispatch = useAppDispatch();
    const wrapperRef = useRef<HTMLDivElement>(null);
    const { areAllScriptsLoaded, isLoaded, isLoading, isActivityJSONFirstLoad, isFirstGameLoad, isFinalRewardTime, isDemoTime } = useAppSelector((state) => state.activity);
	let parentData = useAppSelector((state) => state.activity.parent);
	let { areGameButtonsVisible, activityType, doesNeedRendering, videoSrcWidth, videoSrcHeight, restartDialogOpen } = useAppSelector((state) => state.mini);
    // let { debugSubject, debugScene, debugCharacter } = useAppSelector((state) => state.mini);
    const config: IMiniPathConfig = miniPathConfig;
    const hasVideoPlayed = useAppSelector((state) => state.time.hasPlayed);
    const activityJSON = useAppSelector((state) => state.activity.json);
    let tempFlagRequiresHTMLButtonPadding = useRef(false);

    const {screenWidth, pathBaseWidth} = useAppSelector((state) => state.mini);

    const shouldDisplaySpinner = () => {
        if(activityType === ActivityType.Video || activityType === ActivityType.Song ) {
            return (isLoading && !isLoaded)
        } else {
            return (isFirstGameLoad || (isLoading && !isLoaded));
        }
    }

    const [isWritableVisible, setIsWritableVisible] = useState(false);
    const [isWritableMobile, setIsWritableMobile] = useState(false);
    const [writableLeftOverride, setWritableLeftOverride] = useState<number>(0);
    const [whiteAreaWidth, setWhiteAreaWidth] = useState<number>(0);

    useEffect(() => {
        let pathData = activityJSON.data?.pathData;
        if(pathData && isActivityJSONFirstLoad && activityJSON.data !== undefined){
            let isPathComplete = true;
            pathData.forEach((data: any) => {
                if(!Number(data.finished)){
                    isPathComplete = false;
                }
            }, this);

            if(isPathComplete){
                dispatch(setShouldShowFinalReward(false));
                dispatch(setRestartDialogOpen(true));                
                dispatch(setIsPathComplete(true)); 
            }

            dispatch(setIsActivityJSONFirstLoad(false));
        }
    }, [activityJSON, dispatch, isActivityJSONFirstLoad]);

    useEffect(() => {
        tempFlagRequiresHTMLButtonPadding.current = activityJSON?.data?.miniPathButtonPadding; 
    }, [activityJSON]);

    const determineWritableOffset = useCallback(() => {
        // Center writables ui for mini path
        let writablesInner = document.getElementById("writables-ui-inner");
        if(writablesInner){
            setWhiteAreaWidth(screenWidth - pathBaseWidth);
            let newLeft = (whiteAreaWidth - writablesInner?.offsetWidth) / 2;
            setWritableLeftOverride(newLeft);
        }
    }, [screenWidth, pathBaseWidth, whiteAreaWidth])
    
    useEffect(() => {
        if(isWritableVisible){
            determineWritableOffset();
        }
    }, [determineWritableOffset, isWritableVisible]);

    useEffect(() => {
        // Check if this activity is a 'Path Writable' by checking activity config path
        if(areAllScriptsLoaded && Object.keys(activityJSON).length && activityJSON.data.config && activityJSON.data.config[0].indexOf("path-writable") > -1) {

            if(window.szWritables){
                setIsWritableMobile(window.szWritables?.data?.mobile);
                setIsWritableVisible(window.szWritables?.data?.displayed);
                determineWritableOffset();
            }

            window.szGame.game.state.onStateChange.add((newStateName: string, oldStateName: string) => {
                if (oldStateName === "Preloader") {
                    function checkAndClearWritablesHTML() {
                        const writablesElement = document.getElementById("writables-ui-button-inside");
                        if(writablesElement){
                            setIsWritableVisible(window.szWritables?.data?.displayed);
                            setIsWritableMobile(window.szWritables?.data?.mobile);
        
                            determineWritableOffset();
        
                            // Add observer to writables 'pencil' button to update 'setIsWritableVisible'
                            const observerConfig = { attributes: true, childList: false, subtree: false };
                            window.writablesObserver = new MutationObserver(() => {
                                setIsWritableVisible(window.szWritables?.data?.displayed);
                            });
        
                            window.writablesObserver.observe(writablesElement, observerConfig);

                            return true;
                        } else {
                            return false;
                        }
                    }

                    let timeoutId: ReturnType<typeof setTimeout>;
                    let attempt = 1;
                      
                    function attemptWritablesCheck() {
                        if (checkAndClearWritablesHTML()) {
                            clearTimeout(timeoutId);
                            return;
                        }
                    
                        if (attempt === 12) {
                            return;
                        }
                    
                        attempt++;
                        timeoutId = setTimeout(attemptWritablesCheck, 250);
                    }
                    
                    // Initial attempt
                    timeoutId = setTimeout(attemptWritablesCheck, 250); 
                }
            }, null);

        }
    }, [activityJSON, areAllScriptsLoaded, determineWritableOffset, pathBaseWidth, screenWidth]);

    useEffect(() => {
        const blockPotraitMode = () => {
            const width = window.innerWidth;
            const height = window.innerHeight;
            if (width > 1000 || width > height) {
                dispatch(setIsPortrait(false));
            } else {
                dispatch(setIsPortrait(true));
                dispatch(setOpen(false));
            }
        }

        window.addEventListener("resize", () => {
            blockPotraitMode();
        });

        // Video Player pause on blur event
        window.addEventListener("blur", function() {
            if (hasVideoPlayed && document.getElementById("sz-media-player")) {
                const videoPlayer: HTMLVideoElement = document.getElementById("sz-media-player") as HTMLVideoElement;
                videoPlayer.pause();
            }
        });

        // Video Player play on focus event
        window.addEventListener("focus", function() {
            if (hasVideoPlayed && document.getElementById("sz-media-player")) {
                const videoPlayer: HTMLVideoElement = document.getElementById("sz-media-player") as HTMLVideoElement;
                videoPlayer.play();
            }
        });

        setTimeout(() => {
            blockPotraitMode();
        }, 10);
    });

	const parentListener = (event: any) => {
		/**
		 * Do we trust the sender of this message?
		 */
		 if (
			event.origin !== "https://beta.anywhereteacher.com"
            && event.origin !== "https://dev.mini.anywhereteacher.com"
            && event.origin !== "https://mini.anywhereteacher.com"
			&& event.origin !== "https://anywhereteacher.com"
			&& event.origin !== "http://localhost:8082"
		) {
			return;
		}
        
		/**
		 * Handle communications from the parent and process the environment settings
		 * by dispatching the loading operation
		 */	
		if (!isLoaded && isLoading) {
			window.szLogger.log("Child Listener: Parent Environment", (event.data) ? JSON.parse(event.data) : undefined);

			const settings = JSON.parse(event.data);
			dispatch(loadActivity({ settings: settings }));
            dispatch(setAreGameButtonsCollapsed(false));
            
			/**
			 * set Node ID based on currentLevel in the pathData when entering the mini path
             * TODO: this is causing re-render in ActivityUI Warning
			 */
			dispatch(setActivityId(settings.szLearningPathData.activities[settings.szLearningPathData.currentLevel][0].nid));
            /**
             * use the current level specified to set the anchor level of the navigation
             */
            dispatch(setAnchorLevelByCurrentLevel(settings.szLearningPathData.currentLevel));
		}
		return;
	}
	/**
	 * Listen for a message from the parent that passes the environment settings from 
	 * the parent to the child
	 */
	window.addEventListener("message", parentListener);
    /**
     * Set the body styling only when the it's a fresh load of a new game
     */
    if (isFirstGameLoad) {
        document.getElementById("mini-body")!.style.backgroundColor = "#000000";
        document.getElementById("mini-body")!.style.overflow = "hidden";
    }
    /**
     * Determine scaling for the base-path image (left-side picture) using the source height of the image  
     * and the window's innerHeight to determine the base scaling ratio for all visual components  
     * they are placed on top of the base-path image.
     */
    if (isLoaded) {
		window.removeEventListener("message", parentListener);
	}
	/**
	 * Start the loading process of the activities environment settings
	 * + if it's not already loaded or loading
	 * + set the isLoading state
	 * + send message to the parent to establish communication which should trigger the postMessage from the parent
	 */	
	if (!isLoaded && !isLoading) {
		dispatch(setLoading());
        dispatch(setIsButtonBlocked(true));

        window.addEventListener("resize", () => {
            dispatch(setIsOpen(false));
            dispatch(setIsButtonBlocked(false));
            dispatch(showGameButtons());
            dispatch(setDoesNeedRendering(true));
        })

		window.szLogger.log("%c Establish Communications", "background-color: pink; color: black");
        /**
         * Start Communications with Parent Frame using Post Message 
         * 
         * if browser is **Safari** it takes two (at least) attempts to setup 2 way communications
         */
		sendMessage("START COMMUNICATIONS");
        if (isAppleSafari() || isAppleIOS()) {
            setTimeout(()=> {
                if (!isLoaded) {
                    sendMessage("START COMMUNICATIONS");
                }
            }, 500);
        }

        // Open the title overlay on first visit
        if(parentData?.settings?.szLearningPathData.pathFirstVisit) {
            dispatch(setIsOpen(true));
        }
	}

    const screenLayout = (skin: IMiniPathConfigEntry) => {
        dispatch(setSkin(skin));

        // Source dimens
        const pathBaseSrcWidth = 400;
        const pathBaseSrcHeight = 1440;
        const strokeSourceWidth = 12;

        const innerWidth = window.innerWidth;
        const innerHeight = window.innerHeight;
        // Phaser activity src dimens. Set default dimens since the activity my not exist yet
        let activitySrcWidth = (window.szGame?.game?.width) ? window.szGame?.game?.width : 800; //1280;
        let activitySrcHeight = (window.szGame?.game?.height) ? window.szGame?.game?.height : 600; //720;

        // To optimize layout for videos, retrieve the video src dimensions to use as the activitySrc dimens
        if(activityType === ActivityType.Video || activityType === ActivityType.Song ) {
            if(videoSrcWidth && videoSrcHeight){
                activitySrcWidth = videoSrcWidth;
                activitySrcHeight = videoSrcHeight;
            }
        }

        // Calculate the scale needed to bring the phaser activity to the same scale as the path base
        const tempScaleFactor = pathBaseSrcHeight / activitySrcHeight;
        let activityScaledWidth: number = activitySrcWidth * tempScaleFactor;
        let activityScaledHeight: number = activitySrcHeight * tempScaleFactor;

        // Find the dimens required for both the path base AND the activity content
        const fullContentWidth = pathBaseSrcWidth + activityScaledWidth;
        const fullContentHeight = pathBaseSrcHeight;
        const fullContentAspectRatio = fullContentWidth / fullContentHeight;

        // With both the pathBase and the activity at the same scale, now determine the final fixed aspect ratio
        const screenAspectRatio = innerWidth / innerHeight;
        let miniContentWidth = (screenAspectRatio > fullContentAspectRatio) ? innerHeight * fullContentAspectRatio : innerWidth;

        // Some activities may require a right margin for the html buttons so that they do not cover activity functionality
        if(tempFlagRequiresHTMLButtonPadding.current && window?.szButtons?.data){
            let settings: ISZButtonSettings = window.SZButtons.prototype.determineSettings();
            miniContentWidth -= settings.buttonWidth + (2 * settings.buttonSpacing);
        }
        const miniContentHeight = miniContentWidth / fullContentAspectRatio;

        // Determine ratio to scale any source dimens to the miniContent dimens
        let scaleRatio = miniContentWidth / fullContentWidth;
        // Calculate final dimentions based on the scaleRatio
        const renderPathWidth = (pathBaseSrcWidth * scaleRatio);
        const renderActivityWidth = (activityScaledWidth * scaleRatio);
        const renderActivityHeight = (activityScaledHeight * scaleRatio);
        const strokeRenderWidth = (strokeSourceWidth * scaleRatio);

        // Determine the horizontal position of the activity
        let activityWrapperLeft = renderPathWidth + (innerWidth - renderPathWidth - renderActivityWidth) / 2;
        if(tempFlagRequiresHTMLButtonPadding.current && window?.szButtons?.data) {
            let settings: ISZButtonSettings = window.SZButtons.prototype.determineSettings();
            activityWrapperLeft = renderPathWidth + (innerWidth - renderPathWidth - renderActivityWidth - settings.buttonWidth - settings.buttonSpacing) / 2;
        }

        /**
         * Save all the scaled dimensions
         */
        dispatch(setScreenSize({
            newScaleRatio: scaleRatio,
            screenWidth: innerWidth, 
            screenHeight: innerHeight,
            contentWidth: miniContentWidth,
            contentHeight: miniContentHeight,
            pathBaseWidth: renderPathWidth, 
            activityWidth: renderActivityWidth,
            strokeWidth: strokeRenderWidth
        }));

        /**
         * ### Animate SZ Game Button Show/Hide
         * 
         * @param wrapper       reference to the wrapper
         * @param start         starting opacity of the animation
         * @param end           ending opacity of the animation
         */
        const animateWrapper = (wrapper: HTMLDivElement, start: number, end: number) => {
            wrapper.animate(
                [
                    { opacity: start },
                    { opacity: end }
                ],
                {
                    easing: "linear",
                    iterations: 1,
                    duration: 300
                }
            );
            wrapper.style.top = end.toString();
        }

        /**
         * handle showing and hiding animation of SZ Game Button wrapper
         */
        if (wrapperRef.current) {
            if (areGameButtonsVisible && wrapperRef.current.style.opacity === "0") {
                animateWrapper(wrapperRef.current, 0, 1);
            } else if (!areGameButtonsVisible && wrapperRef.current.style.opacity === "1") {
                animateWrapper(wrapperRef.current, 1, 0);
            }
        }

        return (
            <div id="mini-base" 
                style={{
                    position: "relative",
                    width: innerWidth,
                    height: innerHeight,
                    overflow: "hidden",
                }}
            >
                <div id="mini-scripts"></div>

                { restartDialogOpen && <RestartPathDialog/>}

                { isDemoTime && <DemoVideoPlayer/> }

                { !isFinalRewardTime && <div id="mini-content"
                    style={{
                        position: "relative",
                        top:( innerHeight - miniContentHeight) / 2,
                        left: 0,
                        width: innerWidth,
                        height: miniContentHeight,
                        marginLeft: "auto",
                        marginRight: "auto"
                    }}>

                    <PathTitle 
                        skin={skin}
                        icon={parentData.settings.szLearningPathData.subject}
                    />

                    <PauseDialog />

                    {/* Writables (for mobile layout) needs some style overrides to fit mini path */}
                    {isWritableVisible && isWritableMobile && <style>
                        div#writables-ui {`{`}left: {renderPathWidth}px !important{`}`}
                        button#writables-ui-button-inside {`{`}right: auto !important{`}`}
                        div#writables-ui-inner {`{`}left: {writableLeftOverride}px !important{`}`}
                        div#writables-ui {`{`}width: {whiteAreaWidth}px !important{`}`}                        
                    </style>}

                    <TokenFountain
                        pathWidth={pathBaseSrcWidth}
                        activityWidth={renderActivityWidth}
                    />

                    <div
                        className="path-ui-activity-wrapper open"
                        id="path-ui-activity-wrapper"
                        style={{
                            position: "absolute",
                            top: 0,
                            left: activityWrapperLeft,
                            width: renderActivityWidth,
                            height: renderActivityHeight,
                        }}
                    >
                        <div
                            id="media-wrapper"
                            style={{
                                background: "#000000",
                                width: renderActivityWidth,
                                height: renderActivityHeight
                            }} 
                        >
                            <div id="media-inner">
                                <div
                                    id="phaser-content"
                                    className="phaser-content-div phaser-content-div-open" 
                                    style={{
                                        width: renderActivityWidth,
                                        height: renderActivityHeight
                                    }}
                                >
                                    <Activity skin={ skin } />
                                </div>
                            </div>
                        </div>

                        {(shouldDisplaySpinner()) && 
                            <div
                                style={{
                                    position: "absolute",
                                    width: "56px",
                                    height: "56px",
                                    top:  "50%",
                                    left:  "50%",
                                    transform:  "translate(-50%, -50%)",
                                    color: "#555555",
                                    zIndex: 1060
                                }}
                            >
                                <i 
                                    id="media-spinner"
                                    className="fa fa-circle-o-notch fa-spin"
                                    style={{
                                        fontSize: "56px",
                                        width: "56px",
                                        height: "56px",
                                        color: "#ffffff",
                                        zIndex: 1060
                                    }}
                                />
                            </div>
                        }
                    </div>

                    <div ref={wrapperRef} id="sz-buttons-wrapper" style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: innerWidth,
                        height: miniContentHeight,
                        background: "transparent",
                        zIndex: 1120,
                        pointerEvents: "none",
                        opacity: areGameButtonsVisible ? 1 : 0,
                        overflow: "hidden"
                    }}>
                        {activityType !== ActivityType.None ? <SZGameButtons activityType={activityType} /> : null }
                    </div>

                </div>}

                { isFinalRewardTime && <FinalRewardVideoPlayer
                    videos={[skin.reward.video]}
                    node={skin.reward.node}
                /> }
            </div>
        );
    }

    if (doesNeedRendering && isLoaded) {
        dispatch(setDoesNeedRendering(false));
    }

    if (isLoaded) {
        const scene = parentData?.settings?.szLearningPathData?.scene
            ? parentData.settings.szLearningPathData.scene
            : SceneType.Younger;
        
        const character = parentData?.settings?.sz_profiles?.szPhaserProfile?.active?.characterName 
            ? parentData.settings.sz_profiles.szPhaserProfile.active.characterName 
            : CharacterType.Charlie;

        // if(debugSubject === SubjectType.Debug) {
        //     const allSubjects = Object.values(SubjectType);
        //     const rndSubject = allSubjects[Math.floor(Math.random() * allSubjects.length)];
        //     dispatch(setDebugSubject(rndSubject));
        // }
        // if(debugScene === SceneType.Debug) {
        //     let rndScene = ((Math.random() < 0.5) ? SceneType.Younger : SceneType.Older);
        //     dispatch(setDebugScene(rndScene as SceneType));
        // }
        // if(debugCharacter === CharacterType.Debug) {
        //     const allCharacters = Object.values(CharacterType);
        //     const rndChar = allCharacters[Math.floor(Math.random() * allCharacters.length)];
        //     dispatch(setDebugCharacter(rndChar));
        // }

        // Debug random layout:
        // if(debugScene === SceneType.Debug || debugCharacter === CharacterType.Debug){
        //     debugScene = SceneType.Younger;
        //     debugCharacter = CharacterType.Charlie;
        // }
        // return screenLayout(config.skins.style[debugScene][debugCharacter]);

        // Debug specific layout:
        // return screenLayout(config.skins.style[SceneType.Older][CharacterType.Socrates]);

        return screenLayout(config.skins.style[scene][character]);
    } else {
        return null;
    }
}

export default Mini;

