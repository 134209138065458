/**
 * ## Pause Dialog Button
 * 
 * The restart and continue buttons on the Pause modal, padding and margins  
 * are determined by the value of the props.direction (left | right)
 * 
 * @category Component
 * @module Pause Dialog Button(s)
 */
import { IPauseDialogButtonProps } from "../types/types"

const PauseDialogButton = (props: IPauseDialogButtonProps) => {
	return (
		<div className={`phaser-content-pause-dialog-div phaser-content-pause-dialog-div-${props.direction}`} >
			<div>
				<img
					onClick={props.handler}
					src={props.image}
					className="phaser-content-pause-dialog-images"
					alt=""
				/>
			</div>
			<div>
				<span onClick={props.handler} className="phaser-content-pause-dialog-text-span">{props.text}</span>
			</div>
		</div>
	)
}

export default PauseDialogButton;
