/**
 * ## Mini Path Navigation  
 *   
 * Display the navigation for the mini path levels in a two column 50% offest stack  
 * working from the bottom to the top.  
 *   
 * + Show 10 buttons at most  
 * + When more levels than 10 then add/manage chevron navigation  
 * + move navigation by 2 levels per click  
 *   
 * @category Container
 * @module Mini Path Navigation
 */
import { setActivityId, resetActivity, setCurrentLevel, clearJSON } from '../slices/activitySlice';
import { useAppDispatch, useAppSelector } from "../hooks/hooks";
// import ButtonYoungSocrates from "../components/buttons/ButtonYoungSocrates";
// import ButtonYoungAutumn from "../components/buttons/ButtonYoungAutumn";
import Chevron from "../components/Chevron";
import { setIsButtonBlocked } from "../slices/buttonSlice";
import { navUp, navDown } from '../slices/navigationSlice';
import { setVideoHasPlayed } from "../slices/timeDisplaySlice";
import { setIsMusicPlaying, setOpen } from "../slices/pauseDialogSlice";
import {
    INavigationProps,
    IButtonAbsolutePositionProps 
} from "../types/types";
import NavButton from '../components/NavButton';
import { SceneType } from './PathTitle';
import { RootState } from '../app/store';

export enum ButtonStateType {
    New = "new",
    Partial = "partial",
    Complete = "complete"
};

const Navigation = (props: INavigationProps) => {
	const dispatch = useAppDispatch();
	const { areAllScriptsLoaded, isFirstGameLoad} = useAppSelector((state) => state.activity);

    const pathData = useAppSelector((state) => state.activity.parent.settings?.szLearningPathData ? state.activity.parent.settings.szLearningPathData: {});
    const initialLoadActivities: Array<any> = pathData?.activities;
    let activitiesProgress = useAppSelector((state) => state.activity.json?.data?.pathData ? state.activity.json.data.pathData: {});

    // initialLoadActivites is weirdly structured, so map these to an array
    if(isFirstGameLoad) {
        const asArr = Array.from(Object.keys(initialLoadActivities), (key) => initialLoadActivities[Number(key)]) as any[];
        activitiesProgress = asArr.map((innerArray: Array<any>) => innerArray[0]);
    }

    const { anchorLevel, maxLevelsToShow } = useAppSelector((state) => state.navigation);
    const {skin, newScaleRatio, pathBaseWidth, strokeWidth} = useAppSelector((state) => state.mini);
    const {activityId} = useAppSelector((state: RootState) => state.activity);
    
    const navButtonSourceHeight = (skin.scene === SceneType.Younger) ? 120 : 150;
    const navButtonRenderHeight = navButtonSourceHeight * newScaleRatio;
    const chevronSourceWidth = 96;
    const chevronSourceHeight = 68;
    const chevronRenderHeight = Math.trunc(chevronSourceHeight * newScaleRatio);

	/**
	 * ### Handle the direct navigation click from an activity button
	 * 
	 * + Remove the script tags associated to the previous activity   
     * + Set body background back to black
	 * + Set the following in the state Activity ID in the state  
     *     + Activity ID
     *     + Current level
     *     + isButtonBlocked (block spam clicking on buttons throughout reset process)
	 * + Reset and load the new activity  
	 *
	 * @param currentLevel 			Level of next activity
	 */
	const handleDirectNavigation = (currentLevel: number) => {
        // Ensure PauseDialog is closed
        dispatch(setOpen(false));

        let targetNid = pathData.activities[currentLevel][0].nid;

        // Prevent navigating to same level
        if(targetNid !== activityId){
            document.getElementById("mini-scripts")!.innerHTML = "";
            if (document.getElementById("media-wrapper")) {
                document.getElementById("media-wrapper")!.style.backgroundColor = "#000000";
            }

            // Hide game buttons during activity transition
            if (document.getElementById("sz-buttons-wrapper")) {
                document.getElementById("sz-buttons-wrapper")!.style.display = "none";
            }

            dispatch(clearJSON());
            dispatch(setActivityId(targetNid));
            dispatch(setCurrentLevel(currentLevel));
            dispatch(setIsMusicPlaying(true));
            dispatch(setIsButtonBlocked(true));
            dispatch(setVideoHasPlayed(false));
            dispatch(resetActivity());
        }
	}

    const buttonConfig = {
        width: Math.trunc((pathBaseWidth - strokeWidth) * .45),
        height: Math.trunc(navButtonRenderHeight),
        fontSize: Math.round(navButtonRenderHeight / 2),
        fontColor: skin?.pipTextCompleteColor
    }

	if (pathData) {
        let buttonState: ButtonStateType;
        const currentLevel = Number(pathData.currentLevel);

        const navigationContainerStyle: React.CSSProperties = {
            position: "absolute",
            height: "inherit",
            width: "inherit",
            marginTop: 0
        }

        const numberOfActivities = Object.keys(pathData.activities).length;

        const determineAlternatingPosition = (position: number): IButtonAbsolutePositionProps => {
            let leftOffset = 0;
            // Older scene navigation appears closer together so add additional padding
            if(skin.scene === SceneType.Older) {
                leftOffset = (position % 2) ? buttonConfig.width * -.12 : buttonConfig.width * .12;
            }

            const top = Math.trunc((buttonConfig.height * position) / 2);
            if (position % 2 !== 0) {
                // Right positions
                return {
                    top: top,
                    left: buttonConfig.width + leftOffset
                }
            } else {
                // Left positions
                return {
                    top: top,
                    left: 0 + leftOffset
                }
            }
        }

        let position: number = 0;

		return (
			<div style={navigationContainerStyle}>
                <Chevron
                    id="chevron-nav-up"
                    buttonConfig={buttonConfig}
                    buttonSourceWidth={chevronSourceWidth}
                    buttonSourceHeight={chevronSourceHeight}
                    buttonRenderHeight={chevronRenderHeight}
                    clickable={areAllScriptsLoaded}
                    show={numberOfActivities > maxLevelsToShow && anchorLevel <= (numberOfActivities - maxLevelsToShow) }
                    direction="up"
                    handler={() => {dispatch(navUp())}}
                    buttonAbsolutePosition={determineAlternatingPosition(position)}
                />
				{
					Object.keys(activitiesProgress).reverse().map((key: any, index: number) => {
                        const level = Number(key) + 1;
                        
                        if (level >= anchorLevel && level < (anchorLevel + maxLevelsToShow)) {
                            if (Number(activitiesProgress[key].finished)) {
                                buttonState = ButtonStateType.Complete;
                            } else if (activitiesProgress[key].last_access) {
                                buttonState = ButtonStateType.Partial;
                            } else {
                                buttonState = ButtonStateType.New;
                            }

                            position++;
                            return <NavButton
                                level={numberOfActivities - index}
                                handler={ () => { handleDirectNavigation(level) }} 
                                clickable={areAllScriptsLoaded}
                                key={`path-button-${level}`}
                                active={numberOfActivities - index === currentLevel}
                                buttonState={buttonState}
                                buttonConfig={buttonConfig}
                                buttonAbsolutePosition={determineAlternatingPosition(position)}
                                skin={skin}
                            />
                        } else {
                            return null;
                        }
					})
				}
                <Chevron
                    id="chevron-nav-down"
                    buttonConfig={buttonConfig}
                    buttonSourceWidth={chevronSourceWidth}
                    buttonSourceHeight={chevronSourceHeight}
                    buttonRenderHeight={chevronRenderHeight}
                    clickable={areAllScriptsLoaded}
                    show={numberOfActivities > maxLevelsToShow && anchorLevel > 1}
                    direction="down"
                    handler={() => {dispatch(navDown())}}
                    buttonAbsolutePosition={determineAlternatingPosition(position + 1)}
                />
			</div>
		)
	}
	return null;
}

export default Navigation;
