"use client";

//import React from "react";
import * as ReactDOM from "react-dom/client";
import { 
    createBrowserRouter,
    RouterProvider,
    createRoutesFromElements,
    Route, 
	useRouteError,
	isRouteErrorResponse
} from "react-router-dom";
import { store } from "./app/store";
import { Provider as ReduxProvider } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
/** 
 * React Router: Route Elements
 */
import Mini from "./routes/Mini";
import ErrorPage from "./components/ErrorPage";
/** 
 * Sentry Integration (see https://sentry.io)
 */
import { initSentry, logError } from './lib/errorLib';
/** 
 * AT2
 */
import SZLogger from "./classes/SZLogger";
import ActivityUI from "./components/ActivityUI";
import LandscapeOnlyDialog from "./components/LandscapeOnlyDialog";
import ProgressLoading from "./components/ProgressLoading";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorInfo } from "react";
import "web-animations-js/web-animations.min";

declare global {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface Window {
		szLogger: SZLogger;
        cleanDOM: Array<string>;
		szWritables: any;
	}
}
/**
 * obtain a clean copy of the DOM prior to building up our React DOM
 */
window.cleanDOM = Object.keys(window);
window.szBlockPostRewards = false;
/**
 * initialize SZLogger to manage console logging
 */
window.szLogger = new SZLogger(window.location.hostname, true);

const miniVersion = "1.0.3 Mini";
window.szLogger.verbose("%c SZ Mini Adventure | Version " + miniVersion + " ", "background-color: yellow; color: black");

initSentry();
/** 
 * Routing and Redux integration
 * https://www.npmjs.com/package/@lagunovsky/redux-react-router
 * redux-react-router may not be compatible with react-router v6.4 as it looks like CreateBrowserRouter 
 * uses the DOM History API as opposed to the history package from Remix themselves.  Can't track it down 
 * exactly but see https://reactrouter.com/en/6.4.0-pre.14/routers/history-router for indications and 
 * the history-router is gone from 6.4.1
 * 
 * https://github.com/salvoravida/redux-first-history also may provide some light on how to manage state and browsing history
 * 
 * https://blog.logrocket.com/react-router-with-redux-navigation-state/
 */
const router = createBrowserRouter(
	createRoutesFromElements(
		<Route ErrorBoundary={() => {throw useRouteError()}}>
			<Route
				path="/:pathId"
				loader={({ params }) => {
					return params;
				}}
				element={ <Mini /> }
			/>
		</Route>
	)
);

const root = ReactDOM.createRoot(document.getElementById("root")!);

const handleError = (error: Error, errorInfo: ErrorInfo) => {  
	if (isRouteErrorResponse(error)) {
		// Log routing errors to Sentry as HTML 404: Page not found
		logError(new Error("404: Page Not Found"), {
			componentStack: error.toString()
		});
	} else {
		// Other error
		logError(error, errorInfo);
	}
};

root.render(
	<ReduxProvider store={store}>
		<ThemeProvider theme={theme}>
			<CssBaseline enableColorScheme />
			<ErrorBoundary FallbackComponent={ErrorPage} onError={handleError} >
				<LandscapeOnlyDialog />
				<ActivityUI />
				<RouterProvider
					router={router}
					fallbackElement={<ProgressLoading />}
				/>
			</ErrorBoundary>
		</ThemeProvider>
	</ReduxProvider>
);
