export const sendMessage = (message: string) => {
	let postUrl = "";
	if (window.location.href.match(/^((http):\/)\/(localhost:3003).*$/)) {
		postUrl = "http://localhost:8082"
	} else if (window.location.href.match(/^((https):\/)\/(dev.mini).*$/)) {
		postUrl = "https://beta.anywhereteacher.com"
	} else if (window.location.href.match(/^((https):\/)\/(mini.).*$/)) {
		postUrl = "https://anywhereteacher.com"
	} else {
		return null;
	}

    try {
	    window.parent.postMessage(message, postUrl);
    } catch (error) {
        window.szLogger.log("Post Message: Error", error);
	    sendMessage(message);  
    }
}
