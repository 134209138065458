/**
 * ## Token Fountain Slice
 * 
 * @category Slice
 * @module Token Fountain Slice
 */
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from '@reduxjs/toolkit';

interface ITokenState {
	isVisible: boolean;
    tokenCount: number;
}

const initialState: ITokenState = {
	isVisible: false,
    tokenCount: 0
}

const tokenFountainSlice = createSlice({
	name: "token",
	initialState,
	reducers: {
		setIsTokenFountainVisible: (state, action: PayloadAction<boolean>) => {
			state.isVisible = action.payload;
		},
        setTokenCount: (state, action: PayloadAction<number>) => {
			state.tokenCount = action.payload;
		}
	}
});

export const { setIsTokenFountainVisible, setTokenCount } = tokenFountainSlice.actions;

export default tokenFountainSlice.reducer;
