/**
 * ## Generic Navigation Button
 * 
 * Each skin has a button specifics what are defined in various configs and props.
 * Each button is composed of an SVG that changes based on the button state/activity completeness
 *   
 * **States**  
 * | State | Description |   
 * | ----- | ----------- |  
 * | ButtonStateType.New | when a level has not been visited yet on the path |  
 * | ButtonStateType.Partial | when a level has been visited but not completed |  
 * | ButtonStateType.Complete | when a level has been completed |  
 * | (Optional) Active | Adds a highlight svg to the back of the nav button
 * 
 * Each button has a click handler that can be blocked when activities are loading to intercept  
 * spam tapping.
 * 
 * **Note:** the skin configuration must be aggregated using Gulp.
 * $> gulp mergeSkin --skin mini
 *   
 * @see {@link slices/buttonSlice | Button Slice}  
 *   
 * @category Nav Button Component
 * @module NavButton
 */
import { CSSProperties,  } from "react";
import { useAppSelector } from "../hooks/hooks";
import { IButtonProps } from "../types/types";
import { ButtonStateType } from "../containers/Navigation"
import { SceneType } from "../containers/PathTitle";

const NavButton = (props: IButtonProps) => {
    const isButtonBlocked = useAppSelector((state) => state.button.isButtonBlocked);
    const {skin, newScaleRatio} = useAppSelector((state) => state.mini);

    /**
     * ### Pointer Styling  
     * 
     * @param clickable         is button clickable?
     * @returns                 pointer if clickable, otherwise not-allowed
     */
    const containerStyle = (clickable: boolean) => {
        return ({
            width: props.buttonConfig.width, 
            height: props.buttonConfig.height,
            position: "absolute",
            top: props.buttonAbsolutePosition.top,
            left: props.buttonAbsolutePosition.left,
            cursor: clickable && !isButtonBlocked ? "pointer" : "not-allowed",
            userSelect: "none",

            // backgroundColor: "#FF55FF88",
            // border: "1px solid #000",

            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        } as CSSProperties)
    }

    const numberStyle = () => {
        return ({
            position: "absolute",
            color: (props.buttonState === ButtonStateType.Complete) ? props.skin.pipTextCompleteColor : props.skin.pipTextColor,
            fontFamily: "kg_blank_space_solid",
            fontSize: (40 * newScaleRatio)+ "px",
            margin: 0,
            paddingTop: (skin.buttonTextOffset.percY > 0) ? props.buttonConfig.height * skin.buttonTextOffset.percY : 0,
            paddingBottom: (skin.buttonTextOffset.percY < 0) ? -1 * props.buttonConfig.height * skin.buttonTextOffset.percY : 0,
            paddingLeft: (skin.buttonTextOffset.percX > 0) ? props.buttonConfig.width * skin.buttonTextOffset.percX : 0,
            paddingRight: (skin.buttonTextOffset.percX < 0) ? -1 * props.buttonConfig.width * skin.buttonTextOffset.percX : 0,
        } as CSSProperties)
    }

    const pipStyle = () => {
        return ({
            position: "absolute", 
            maxWidth: "100%", 
            maxHeight: "100%",
            padding: ((props.skin.scene === SceneType.Older) ? 3 : 0) + "px"
        } as CSSProperties)
    }

    /**
     * ### Button Click Handler  
     * 
     * @param clickable         is button clickable?
     * @returns                 handler if clickable, otherwise void function
     */
    const handleClick = (clickable: boolean) => {
        return clickable && !isButtonBlocked ? props.handler : () => {};
    }

    const renderPip = (buttonState: ButtonStateType, isSelected: boolean) => {
        let fragment = [];

        // Younger pip hightlight goes on bottom
        if(isSelected && skin.scene === SceneType.Younger){
            fragment.push(<img 
                key={"nav-button-highlight-" + props.level}
                src={"images/skins/" + skin.scene + "/" + skin.name + "/pip-selected.svg"}
                alt={"Progress icon"}
                style={pipStyle()}
            />);
        }

        switch(props.buttonState) {
            case ButtonStateType.New: {
                fragment.push(<img 
                    key={"nav-button-" + props.level}
                    src={"images/skins/" + skin.scene + "/" + skin.name + "/pip-" + props.buttonState + ".svg"}
                    alt={"Progress icon"}
                    style={pipStyle()}
                />);
                break;
            }
            case ButtonStateType.Partial: {
                fragment.push(<img 
                    key={"nav-button-" + props.level}
                    src={"images/skins/" + skin.scene + "/" + skin.name + "/pip-" + props.buttonState + ".svg"}
                    alt={"Progress icon"}
                    style={pipStyle()}
                />);
                break;
            }
            case ButtonStateType.Complete: {
                fragment.push(<img 
                    key={"nav-button-" + props.level}
                    src={"images/skins/" + skin.scene + "/" + skin.name + "/pip-" + props.buttonState + ".svg"}
                    alt={"Progress icon"}
                    style={pipStyle()}
                />);
                break;
            }
        }

        // Older pip hightlight goes on top
        if(isSelected && skin.scene === SceneType.Older){
            fragment.push(<img 
                key={"nav-button-highlight-" + props.level}
                src={"images/skins/" + skin.scene + "/" + skin.name + "/pip-selected.svg"}
                alt={"Progress icon"}
                style={pipStyle()}
            />);
        }

        return <>{fragment}</>
    }
    return (
        <div style={containerStyle(props.clickable)} onClick={handleClick(props.clickable)} key={`svg-nav-${props.level}`}>
            
            {renderPip(props.buttonState, props.active)}

            <h2 style={numberStyle()}>
                {props.level}
            </h2>
        </div>
    )
}

export default NavButton;
