/**
 * ## Landscape Only Slice
 * 
 * @category Slice
 * @module Landscape Only Slice
 */
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from '@reduxjs/toolkit';

interface ILandscapeState {
	isPortrait: boolean;
}

const initialState: ILandscapeState = {
	isPortrait: true,
}

const landscapeOnlyDialogSlice = createSlice({
	name: "landscape",
	initialState,
	reducers: {
		setIsPortrait: (state, action: PayloadAction<boolean>) => {
			state.isPortrait = action.payload;
		}
	}
});

export const { setIsPortrait } = landscapeOnlyDialogSlice.actions;

export default landscapeOnlyDialogSlice.reducer;
