/**
 * ## Activity UI  
 *   
 * This is the replacemant wrapper for activity_ui.js dependency of AT1.  
 *   
 * We provide call outs to the DOM from the Phaser world that were implemented in:  
 * + activity_ui.js  
 * + anywhere_teacher.js  
 * + others scattered throughout other javascript files  
 * 
 * @category Component
 * @module Activity UI
 */
import { useAppDispatch, useAppSelector } from "../hooks/hooks";
import {
    completeCurrentLevel,
    setActivityId,
    resetActivity,
    setRewardsCallback,
    setIsFinalRewardTime,
} from "../slices/activitySlice";
import { sendMessage } from "../lib/iFrameCommunications";
import { setIsMusicPlaying, setOpen } from "../slices/pauseDialogSlice";
import { setIsButtonBlocked } from "../slices/buttonSlice";
import { setIsTokenFountainVisible, setTokenCount } from "../slices/tokenFountainSlice";
import { setAnchorLevelByCurrentLevel } from "../slices/navigationSlice";
import { setVideoHasPlayed } from "../slices/timeDisplaySlice";
import { setTotalTokenBalance } from "../slices/activitySlice";
import { RootState } from "../app/store";
import { mapUrl } from "../lib/urlTools";

export enum ActivityType {
    None = "",
    Phaser = "phaser",
    InteractiveWorksheet = "interactive_worksheet",
    Book = "book",
    FlashCardsPhaser = "flash_cards_phaser",
    Video = "video",
    Song = "song",
};

declare global {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface Window {
		szLearningPathReward: boolean;
		szHidePhaserArrows: any;
		szShowPhaserArrows: any;

		showMediaOverlay: any;
        closeMediaOverlay: any;
        hideMediaOverlay: any;
        playMedia: any;

		szProfileRewards: any;
        szGaEvent: any;
        szGetSpeakerButton: any;
        szEnableSpeakerButton: any;
        videoPaused: any;
        videoEnded: any;
        hidePauseOverlay: any;
        play: any;
        pause: any;
        toggleFullscreen: any;
        startFullscreen: any;
        fullscreenButtonClick: any;
        activityFinished: any;
        togglePause: any;
        exitActivity: any;
        restart: any;
        toggleMusic: any;
        toggleHelp: any;
        toggleHome: any;
        speakerButtonClicked: any;
        phaserNavLeft: any;
        phaserNavRight: any;
	}
}

export interface IRewardItem {
    nid: string;
    pid: string;
    uid: string;
    value: number;
}

export interface IRewardsPendingNode {
    [index: string]: IRewardItem;
}

export interface IRewardsPending {
    [index: string]: IRewardsPendingNode;
}

const ActivityUI = () => {
	const dispatch = useAppDispatch();
    const pathSettings = useAppSelector((state) => state.activity.parent.settings ? state.activity.parent.settings : {});
	const pathData = useAppSelector((state) => state.activity.parent.settings?.szLearningPathData ? state.activity.parent.settings.szLearningPathData : {});
	const profileID = useAppSelector((state) => state.activity.parent.settings?.sz_profiles?.szPhaserProfile?.active?.pid ? state.activity.parent.settings.sz_profiles.szPhaserProfile.active.pid : 0);
    const {activityId, shouldShowFinalReward} = useAppSelector((state: RootState) => state.activity);
    const {isPathComplete} = useAppSelector((state: RootState) => state.mini);

	let { hasRewardCallback } = useAppSelector((state) => state.activity);
    /**
     * ### Scan Path Data for next Non-Finished Level
     * 
     * search from current level to the last activity, return next non-finished activity
     * when no activity found from first phase, search from head to current level, return next non-fnihsed activity
     * 
     * @param currentLevel              current level just finished
     * @param maxLevel                  max number of levels in this mini path
     * @param activities                activities in this mini path
     * @returns                         0 if no unfinished activities found, otherwise next activity to run
     */
    const scanForNextAvailableLevel = (currentLevel: number, maxLevel: number, activities: any) => {
        let nextLevel = 0;

        if(isPathComplete){
            // Gets next level in rotation
            nextLevel = (currentLevel + 1) % maxLevel;
        } else {
            // Gets next incomplete level
            for (let searchLevel = currentLevel + 1; searchLevel <= maxLevel; searchLevel++) {
                if (activities[searchLevel - 1][0].finished === 0) {
                    nextLevel = searchLevel;
                    break;
                }
            } 
            // Circle back and check beginning levels for any incomplete
            if (currentLevel > 1 && nextLevel === 0) {
                for (let searchLevel = 1; searchLevel < currentLevel; searchLevel++) {
                    if (activities[searchLevel - 1][0].finished === 0) {
                        nextLevel = searchLevel;
                        break;
                    }
                } 
            }
        }
        
        window.szLogger.log("%c Next Level", "background-color: yellow; color: black", nextLevel);
        return nextLevel;
    }
    /**
     * ### Select the Next Activity to Play
     * 
     * If a **new activity is found** to run
     * + show the token fountain  
     * + wait for the token fountain animation  
     * ---------------------------------------------------  
     * + hide the token fountain  
     * + complete the current level  
     * + set the next activity ID  
     * + reset the mini path environment for the next activity  
     *   
     * If **no activity found** (mini path complete)  
     * + all levels are complete  
     * + set last level as complete  
     * + clear activity ID  
     * + reset the path environment to play the rewards animation   
     *   
     * @param pathData              Profile specific path data
     */
	const nextActivity = (pathData: any, profileID: number) => {
		const activities: any = Object.values(pathData.activities);
		const newCurrentLevel = scanForNextAvailableLevel(Number(pathData.currentLevel), pathData.maximumLevel, activities);
		if (newCurrentLevel > 0) {
            // Activity Found!
            // Pull the rewards from local storage and determine how many tokens to emit in the token fountain
            let totalTokens = 1;
            const rewards = localStorage.getItem("szProfileRewardsPending");
            if (rewards) {
                const rewardsObject: IRewardsPending = JSON.parse(rewards);
                if (rewardsObject[`profile_${profileID}`]) {
                    const rewardsForProfile = rewardsObject[`profile_${profileID}`];
                    if (rewardsForProfile[`node_${activityId}`]) {
                        totalTokens = rewardsForProfile[`node_${activityId}`].value;
                    }
                }
            }

            // Hide game buttons during activity transition
            if (document.getElementById("sz-buttons-wrapper")) {
                document.getElementById("sz-buttons-wrapper")!.style.display = "none";
            }

            let delay = 1;
            if (totalTokens > 0) {
                dispatch(setTokenCount(totalTokens));
                dispatch(setIsTokenFountainVisible(true));
                delay = 3800;
            }

            dispatch(setIsButtonBlocked(true));

            // Wait for token fountain to finish
            setTimeout(() => {
                dispatch(setVideoHasPlayed(false));
                dispatch(setTotalTokenBalance(totalTokens));
                dispatch(setTokenCount(0));
                dispatch(setIsTokenFountainVisible(false));
                dispatch(setAnchorLevelByCurrentLevel(newCurrentLevel));

                dispatch(completeCurrentLevel(newCurrentLevel));
                dispatch(setActivityId(activities[newCurrentLevel - 1][0].nid));
            
                dispatch(setIsMusicPlaying(true));
                dispatch(resetActivity());
            }, delay);
		} else {
            // **No Activity Found** (Path Complete!)
			dispatch(completeCurrentLevel(Number(pathData.currentLevel)))
			dispatch(setActivityId(0));

            if(shouldShowFinalReward){
                dispatch(setIsFinalRewardTime(true));

                // Asynchronously trigger path complete (Path complete Email)
                let pathId = pathSettings.szLearningPathId;
                let uid = pathSettings.sz_profiles.szPhaserProfile.uid;
                let pid = pathSettings.sz_profiles.szPhaserProfile.active.pid;
                let completePath = mapUrl() + "/adventure/complete/" + pathId + "/" + uid + "/" + pid;

                fetch (completePath)
                .then(response => response.json())
                .then(json => {
                    window.szLogger.log("%c Path Complete Fetch Complete:", "background-color: pink; color: black", json);
                })
                .catch(error => {
                    window.szLogger.log("Error: Path Complete Fetch Error", error);
                });
            }
			
            requestAnimationFrame(() => {
                dispatch(resetActivity());
            });
		}
	}

    /**
     * ### Callback for Post Rewards method
     */
	const rewardsCallback = () => {
		// Debounce to stop double calls
		if (!hasRewardCallback) {
			window.szLogger.log("%c Rewards Callback", "background-color: pink; color: black");

			dispatch(setRewardsCallback(true));
			hasRewardCallback = true;

			nextActivity(pathData, profileID);
		} else {
			dispatch(setRewardsCallback(false));
			hasRewardCallback = false;
		}
	}

    /**
     * ### Shows a "carousel" arrow for Phaser games  
     * 
     * @param side              side to show the arrow  
     */
    window.szShowPhaserArrows = (side: string) => {
        if (typeof side === 'undefined') {
            document.querySelector('.phaser-arrow-button-left')?.classList.remove('phaser-arrows-hidden')
            document.querySelector('.phaser-arrow-button-right')?.classList.remove('phaser-arrows-hidden')
        } else {
            document.querySelector('.phaser-arrow-button-' + side)?.classList.remove('phaser-arrows-hidden')
        }
    }

    /**
     * ### Hides a "carousel" arrow for Phaser games  
     * 
     * @param side              side to hide the arrow  
     */
    window.szHidePhaserArrows = (side: string) => {
        if (typeof side === 'undefined') {
            document.querySelector('.phaser-arrow-button-left')?.classList.add('phaser-arrows-hidden')
            document.querySelector('.phaser-arrow-button-right')?.classList.add('phaser-arrows-hidden')
        } else {
            document.querySelector('.phaser-arrow-button-' + side)?.classList.add('phaser-arrows-hidden')
        }
    }
    /**
     * ### activity_ui.js DOM Methods
     * 
     * Override those functions required to run a game, otherwise stub out the original  
     * method and produce console log to indicate that the method is getting called from  
     * Phaser back to mini path  
     * 
     */
    if (window.Drupal && window.Drupal.behaviors) {
        window.Drupal.behaviors.szActivityUI = {
            activityFinished: () => {
                if (!window.szBlockPostRewards) {
                    window.szLogger.log("%c Activity Finished", "background-color: pink; color: black");

                    window.szProfileRewards.postRewards(rewardsCallback);
                }
                window.szBlockPostRewards = true;
            },
            attach: () => {
                window.szLogger.log("%c szActivityUI: attach", "background-color: indigo; color: white")
            },
            exitActivity: () => {
                sendMessage("CLOSE MINI");
            },
            fullscreenButtonClick: () => {
                window.szLogger.log("%c szActivityUI: fullscreenButtonClick", "background-color: indigo; color: white")
            },
            game: {},
            hidePauseOverlay: () => {
                window.szLogger.log("%c szActivityUI: hidePauseOverlay", "background-color: indigo; color: white")
            },
            pause: () => {
                window.szLogger.log("%c szActivityUI: pause", "background-color: indigo; color: white")
            },
            phaserNavLeft: () => {
                window.szLogger.log("%c szActivityUI: phaserNavLeft", "background-color: indigo; color: white")
            },
            phaserNavRight: () => {
                window.szLogger.log("%c szActivityUI: phaserNavRight", "background-color: indigo; color: white")
            },          
            play: () => {
                window.szLogger.log("%c szActivityUI: play", "background-color: indigo; color: white")
            },
            restart: () => {
                window.szLogger.log("%c szActivityUI: restart", "background-color: indigo; color: white")
            },
            setupWritable: () => {
                window.szLogger.log("%c szActivityUI: setupWritable", "background-color: indigo; color: white")
            },
            speakerButtonClicked: () => {
                window.szLogger.log("%c szActivityUI: speakerButtonClicked", "background-color: indigo; color: white")
            },
            startFullscreen: () => {
                window.szLogger.log("%c szActivityUI: startFullscreen", "background-color: indigo; color: white")
            },
            toggleFullscreen: () => {
                window.szLogger.log("%c szActivityUI: toggleFullscreen", "background-color: indigo; color: white")
            },
            toggleHelp: () => {
                window.szLogger.log("%c szActivityUI: toggleHelp", "background-color: indigo; color: white")
            },
            toggleHome: () => {
                window.szLogger.log("%c szActivityUI: toggleHome", "background-color: indigo; color: white")
            },
            toggleMusic: () => {
                window.szLogger.log("%c szActivityUI: toggleMusic", "background-color: indigo; color: white")
            },
            togglePause: () => {
                window.szLogger.log("%c szActivityUI: togglePause", "background-color: indigo; color: white")
            },
            videoEnded: () => {
                window.szLogger.log("%c szActivityUI: videoEnded", "background-color: indigo; color: white")
            },
            videoPaused: () => {
                window.szLogger.log("%c szActivityUI: videoPaused", "background-color: indigo; color: white")
            }
        }
    }
    /**
     * Global DOM Methods
     */
    window.szGaEvent = () => {
        window.szLogger.log("%c Global Function: szGaEvent", "background-color: indigo; color: white")
    };
    window.videoPaused = () => {
        window.szLogger.log("%c Global Function: videoPaused", "background-color: indigo; color: white")
    };
    /**
     * ### Close Pause Overlay  
     */
    window.hideMediaOverlay = () => {
        dispatch(setOpen(false));
    }
    /**
     * ### Close Pause Overlay  
     */
    window.closeMediaOverlay = () => {
        dispatch(setOpen(false));
    }
    window.szEnableSpeakerButton = () => {
        window.szLogger.log("%c Global Function: szEnableSpeakerButton", "background-color: indigo; color: white")
    }
    /**
     * ### Fire Off Post Rewards  
     * 
     * There used to be a media overlay - it is now defunct but the  
     * method still gets called but does nothing but wrap postRewards method  
     */
	window.showMediaOverlay = () => {
        if (!window.szBlockPostRewards) {
            window.szLogger.log("%c Show Media Overlay", "background-color: pink; color: black");
            window.szProfileRewards.postRewards(rewardsCallback);
        }
        window.szBlockPostRewards = true;
	};
    
    window.szPhaserCDN = (): any => {
        return "https://anywhereteacher.com/content/activities/games/";
    }

    window.szPhaserGlobalCDN = (): any => {
        return "https://anywhereteacher.com/content/activities/global/";
    }
    /**
     * ! made ActivityUI a functional component so we could dispatch actions,   
     * ! return null since there is no renderable output  
     */
    return null;
}

export default ActivityUI;
