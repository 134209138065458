/**
 * ## Navigation Slice
 * 
 * @category Slice
 * @module Navigation Slice
 */
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from '@reduxjs/toolkit';

interface INavigationState {
    anchorLevel: number;
    numberOfLevelsToNavigate: number;
    maxLevelsToShow: number;
}

const initialState: INavigationState = {
    anchorLevel: 1,
    numberOfLevelsToNavigate: 2,
    maxLevelsToShow: 10
}

const navigationSlice = createSlice({
	name: "navigation",
	initialState,
	reducers: {
        setAnchorLevel: (state, action: PayloadAction<number>) => {
            state.anchorLevel = action.payload
        },
        navUp: (state) => {
            state.anchorLevel = state.anchorLevel + state.numberOfLevelsToNavigate;
        },
        navDown: (state) => {
            state.anchorLevel = state.anchorLevel - state.numberOfLevelsToNavigate < 1 ? 1 : state.anchorLevel - state.numberOfLevelsToNavigate;
        },
        setAnchorLevelByCurrentLevel: (state, action: PayloadAction<number>) => {
            if (action.payload <= state.maxLevelsToShow) {
                state.anchorLevel = 1
            } else {
                const targetLevel = action.payload - state.maxLevelsToShow + state.numberOfLevelsToNavigate;
                state.anchorLevel = targetLevel % 2 === 0 ? targetLevel - 1 : targetLevel;
            }
        },         
	}
});

export const { setAnchorLevel, navUp, navDown, setAnchorLevelByCurrentLevel } = navigationSlice.actions;

export default navigationSlice.reducer;
