/**
 * ## Token Fountain
 * 
 * Present to token fountain animation upon the completion of an activity:   
 * + Shoot 1 token when 1, 2 when 2, when more than 2 then (count / 2) + 1  
 * + Randomize  
 *     + color of sparkle  
 *     + departure location off screen bottom within 300 px of center  
 *     + spin of sparkles  
 *     + time from launch to target  
 * 
 * Target token dialog slides down from top and then hides above top when tokens have all landed
 * 
 * @category Container
 * @module Token Fountain
 */
import { useEffect } from "react";
import Box from "@mui/material/Box";
import { useAppSelector } from "../hooks/hooks";
import Avatar from "../components/Avatar";

declare global {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface Window {
        resumeGame: any;
        restartGame: any;
	}
}

const TokenFountain = (props: any) => {
	const { isVisible, tokenCount } = useAppSelector((state) => state.token);
    const totalTokenBalance = useAppSelector((state) => state.activity.parent.settings.sz_profiles.szProfileRewardsTotal);
    const { contentHeight } = useAppSelector((state) => state.mini);

    /**
     * ## Add sparkles to an animated token  
     * 
     * @param token          token to sparklerize  
     * @param destTop       top position of the animation destination for the token  
     */
    const addSparkles = (token: any, destTop: number = 0) => {
        let sparkleInterval: any;
        const sparklePath = 'M32.487,17.938C14.2,16.495,15.37,15.325,16.812,33.612c1.442-18.287,2.612-17.117-15.675-15.675c18.287,1.443,17.117,2.612,15.675-15.675C15.37,20.55,14.2,19.381,32.487,17.938z';
        sparkleInterval = window.setInterval(() => {
            const sparkleSVG = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
            sparkleSVG.setAttribute('version', '1.1');
            sparkleSVG.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
            sparkleSVG.setAttribute('xmlns:xlink', 'http://www.w3.org/1999/xlink');
            sparkleSVG.setAttribute('x', '0px');
            sparkleSVG.setAttribute('y', '0px');
            sparkleSVG.setAttribute('width', '35px');
            sparkleSVG.setAttribute('height', '35px');
            sparkleSVG.setAttribute('viewBox', '0 0 35 35');
            sparkleSVG.setAttribute('enable-background', 'new 0 0 35 35');
            sparkleSVG.setAttribute('xml:space', 'preserve');

            sparkleSVG.classList.add('token-sparkle-svg');

            const svgPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');

            const colorSelector = ["#ffffff", "#008000", "#33FF57", "#800080", "#FF00FF", "#00FFFF", "#ffff00", "#00FF00"];
            const colorIndex = Math.floor(Math.random() * (colorSelector.length - 1));
            svgPath.style.fill = colorSelector[colorIndex];

            svgPath.setAttribute('d', sparklePath);
            sparkleSVG.appendChild(svgPath);

            const left = Math.floor(token.offsetLeft + Math.random() * token.offsetWidth / 2);
            const top = Math.floor(token.offsetTop + Math.random() * token.offsetHeight / 2);

            sparkleSVG.style.left = left + 'px';
            sparkleSVG.style.top = top + 'px';
            
            if (top && left && top !== 0 && left !== 0) {
                document.body.appendChild(sparkleSVG);

                const sparkleSpin = Math.floor(Math.random() * (1500 - 400) + 400);
                sparkleSVG.animate(
                    [
                        { transform: "rotate(0)"},
                        { transform: "rotate(360deg)" }
                    ],
                    {
                        easing: "linear",
                        iterations: Infinity,
                        duration: sparkleSpin
                    }
                );
                
                const sparkleDuration = Math.floor(Math.random() * 250);
                const sparkleAnimation = sparkleSVG.animate(
                    {
                        width: ["0px", "25px"],
                        height: ["0px", "25px"],
                    },
                    {
                        easing: "linear",
                        iterations: 1,
                        duration: sparkleDuration
                    }
                );

                sparkleAnimation.addEventListener("finish", () => {
                    sparkleAnimation.cancel();
                    window.setTimeout(function () {
                        if(sparkleSVG && document.body.contains(sparkleSVG)){
                            document.body.removeChild(sparkleSVG);
                        }
                    }, 500);
                });
                if (top < destTop) {
                    clearInterval(sparkleInterval);
                }
            } else {
                if(sparkleSVG && document.body.contains(sparkleSVG)){
                    document.body.removeChild(sparkleSVG)
                }
                clearInterval(sparkleInterval);
            }
        }, 10);
    };

    useEffect(() => {
        if (isVisible) {
            const cloudSize = Math.trunc(tokenCount / 2) + 1;
            setTimeout(() => {
                document.querySelector("#token-fountain-dialog .MuiBox-root")!.animate(
                    {
                        top: [-250 + "px", "25px"]
                    },
                    {
                        easing: "ease-in-out",
                        iterations: 1,
                        duration: 1000
                    }
                );
                document.getElementById("token-fountain-dialog")!.style.top = "25px";

                setTimeout(() => {
                    const tokenTarget = document.getElementById("dialog-token");
                    const tokenTargetCoords = tokenTarget?.getBoundingClientRect();
                    const innerHeight = window.innerHeight;
                    /**
                     * create token cloud
                     */
                    const cloneSource = document.getElementById("reward-token")!;
                    let clone: any;
                    for (let i = 0; i < cloudSize; i++) {
                        clone = cloneSource.cloneNode(false);
                        clone.id = "reward-token-" + i.toString();
                        document.getElementById("root")!.appendChild(clone);
                    }

                    let intervalCount = 0;
                    let leftOffset = 0;
                    let animationDuration = 0;
                    const tokenCloudInterval = setInterval(() => {
                        if (intervalCount < cloudSize) {
                            leftOffset = Math.floor(Math.random() * (300)) - 150;
                            animationDuration = Math.floor(Math.random() * (1800 - 1000) + 1000);

                            const token =  document.querySelector("#reward-token-" + intervalCount.toString());
                            if(token){
                                token.animate(
                                    {
                                        bottom: [-100 + "px", (innerHeight - tokenTargetCoords!.top - tokenTargetCoords!.height) + "px"],
                                        left: [Math.trunc(props.pathWidth + (props.activityWidth / 2) - leftOffset) + "px", tokenTargetCoords!.left + "px"]
                                    },
                                    {
                                        easing: "ease-in-out",
                                        iterations: 1,
                                        duration: animationDuration
                                    }
                                );
                                addSparkles(document.getElementById("reward-token-"  + intervalCount.toString()), tokenTargetCoords!.bottom);
                                document.getElementById("reward-token-"  + intervalCount.toString())!.style.left = tokenTargetCoords!.left + "px";
                                document.getElementById("reward-token-"  + intervalCount.toString())!.style.bottom = (innerHeight - tokenTargetCoords!.top - tokenTargetCoords!.height) + "px";
                            }
                        } else {
                            clearInterval(tokenCloudInterval);
                        }
                        intervalCount++;
                    }, 50);
                }, 1000)

                setTimeout(() => {
                    document.getElementById("total-tokens")!.innerHTML = totalTokenBalance + tokenCount;
                    /**
                     * clean up token cloud
                     */
                    for (let i = 0; i < cloudSize; i++) {
                        let element = document.getElementById("reward-token-" + i.toString())
                        element && element.remove();
                    }
                    
                    document.querySelector("#token-fountain-dialog .MuiBox-root")!.animate(
                        {
                            top: ["25px", -250 + "px"]
                        },
                        {
                            easing: "ease-in-out",
                            iterations: 1,
                            duration: 1000
                        }
                    );
                    document.getElementById("token-fountain-dialog")!.style.top = "-250px";
                    const element: HTMLElement = document.querySelector("#token-fountain-dialog .MuiBox-root")!;
                    element.style.top = "-250px";
                }, 2800 + ((cloudSize - 1) * 50 + 250));
            }, 10);
        } 
    })

    let dialogHeight = 200;
    const container = document.getElementById("avatar-container");
    if(container){
        const dialogStyle = window.getComputedStyle(container);
        dialogHeight = parseFloat(dialogStyle.getPropertyValue('height'));
    }

	return (
		<>
            <Box
                id="token-fountain-dialog-container"  
                style={{
                    position: "absolute", 
                    zIndex: 2000, 
                    width:"100%", 
                    display: (isVisible) ? "flex" : "none", 
                    alignItems: "normal", 
                    justifyContent: "center", 
                    top: ((contentHeight/2) - (dialogHeight/2) - 14) + "px", 
                    height: "100vh"
                }}
            >
                <Box
                    id="token-fountain-dialog"                    
                >                
                    <Box className="token-fountain-dialog">
                        <Avatar />
                    </Box>
                </Box>
                <img
                    id="reward-token"
                    src="https://anywhereteacher.com/sites/default/files/coins/at-alt-logo-coin-96-fs8.png"
                    alt="anywhere teacher token"
                    style={{
                        position: "absolute",
                        bottom: -100,
                        left: Math.trunc(props.pathWidth + (props.activityWidth / 2)),
                        width: 60,
                        height: 60,
                        zIndex: 2001
                    }}
                />                
            </Box>
		</>
	);
}

export default TokenFountain;
