/**
 * ## Progress Loading  
 *   
 * TODO: add centered static image  
 *   
 * @category Component  
 * @module Progress Loading  
 */
const ProgressLoading = () => {
	return (
		<div id="phaser-wait" className="phaser-wait-div">
		</div>
	)
}

export default ProgressLoading;
