/**
 * ## Video/Song Player
 * 
 * **HTML5 video element** player with Anywhere Teacher time and progress tracking using the **sz_reporting.js**  
 * class.  Progress, unlike AT1 proper, does not award completion at 80% but requires 100% completion so that  
 * the video can transition to the next available level. Progress is a simple 1 out of 1 fixed progress upon  
 * completion.  
 * 
 * Time tracking is managed using the **DefaultTimeTracker** and is controlled via resume() and suspend() methods and  
 * manages the calculations for elpased time since last report.
 * 
 * The **video background** by default is black, but switches based on the field_background_color activity setting when set.
 * 
 * The **aspect ratio** is also an activity setting, field_video_aspect_ratio, and responsive CSS is applied to the video elelemnts  
 * based on that setting, default is 16:9.  
 * 
 * @returns HTML5 Video Player
 * @module Video & Song Player
 * @category Component
 */
import { useAppSelector, useAppDispatch } from "../hooks/hooks";
import { setIsButtonBlocked } from "../slices/buttonSlice";
import { setOpen } from "../slices/pauseDialogSlice";
import { setTimeDuration, setTimeSeconds, setVideoHasPlayed } from "../slices/timeDisplaySlice";
import { IVideoSongPlayerProps } from "../types/types"
import { useEffect, useRef } from "react";
import { showGameButtons, hideGameButtons, setVideoSrcSize } from "../slices/miniSlice";
import { createVideoPlayButton } from "../containers/activity/utils/activityUtils";

const VideoSongPlayer = (props: IVideoSongPlayerProps) => {
    const dispatch = useAppDispatch();
    const videos = props.videos;
    const node = props.node;
    const videoElementRef = useRef<HTMLVideoElement>(null);
    let { areGameButtonsVisible } = useAppSelector((state) => state.mini);

	const { hasPlayed } = useAppSelector((state) => state.time);
    
	const videoBackgroundColor = node.field_background_color?.und?.length > 0 ? node.field_background_color.und[0].rgb : "#000000";
    
    /**
     * Set the background color in the media-wrapper so the mutation observer will pick it up and move it to the body
     */
    if (document.getElementById("media-wrapper")) {
        document.getElementById("media-wrapper")!.style.backgroundColor = videoBackgroundColor;
    }

    /**
     * determine the assigned aspect ratio of the video, default to 16:9
     */
    const aspectRatio = node.field_video_aspect_ratio?.und?.length > 0 ? node.field_video_aspect_ratio.und[0].value : "16:9";
    let openingVideoElements = {
        outerClass: "",
        innerClass: "",
    };

    if (aspectRatio === '4:3') {
        openingVideoElements.outerClass = "responsive-4by3-wrapper";
        openingVideoElements.innerClass = "embed-responsive-4by3";            
    } else if (aspectRatio === '3:4') {
        openingVideoElements.outerClass = "responsive-3by4-wrapper";
        openingVideoElements.innerClass = "embed-responsive-3by4";            
    } else if (aspectRatio === '722:1000') {
        openingVideoElements.outerClass = "responsive-722by1000-wrapper";
        openingVideoElements.innerClass = "embed-responsive-722by1000";            
    } else {
        openingVideoElements.outerClass = "responsive-16by9-wrapper";
        openingVideoElements.innerClass = "embed-responsive-16by9";            
    }

    const getVideo = () => {
        let video;

        // Don't bother with webm files, thanks iOS
        videos.forEach((videoURL: string) => {
            if(!(videoURL.indexOf('.webm') > -1)) {
                video = <source src={videoURL} type='video/mp4; codecs="avc1.4D401E, mp4a.40.2"'/>;
            }
        });

        return video;
    }

    if(videoElementRef.current){
        videoElementRef.current.addEventListener('loadedmetadata', (event: any) => {
            dispatch(setVideoSrcSize({
                videoSrcWidth: videoElementRef.current!.videoWidth, 
                videoSrcHeight: videoElementRef.current!.videoHeight
            }))

            dispatch(setTimeDuration(event.target.duration));
        });
    }

    useEffect(() => {
        if(videoElementRef?.current){
            const playPromise = videoElementRef.current.play();
            if (playPromise !== undefined) {
                playPromise.then(_ => {
                    // Autoplay started!
                }).catch(error => {
                    // Autoplay not allowed!
                    dispatch(hideGameButtons());
                    createVideoPlayButton(document.getElementById("video-wrapper"), videoElementRef);
                });
            }
        }
    }, [dispatch]);

    /**
     * ### onPlay Event Handler  
     * 
     * @param event
     */
    const onPlay = (event: any) => {
        dispatch(setVideoHasPlayed(true));
        dispatch(setTimeDuration(event.target.duration));
        dispatch(setIsButtonBlocked(false)) ;
        dispatch(setOpen(false));

        event.target.poster = "";
        window?.videoTime?.resume();
    }
    /**
     * 
     * @param event 
     */
    const onPause = (event: any) => {
        window.videoTime.suspend();
    }
    /**
     * 
     * @param event 
     */
    const onTimeUpdate = (event: any) => {
        const currentTime = event.target.currentTime;
        dispatch(setTimeSeconds(currentTime));
    }

    // let finalURI = "";
    // const URI = node.field_screenshot.und[0].uri;
    // const path = "/sites/default/files/";
    // finalURI = URI.replace("public://", mapUrl() + path);

    /**
     * 
     * @param event 
     */
    const onDurationChange = (event: any) => {
        if (!hasPlayed || window.progressLogger === undefined || window.videoTime === undefined) {
            window.progressLogger = new window.szReport.Logger();
            window.videoTime = new window.szReport.DefaultTimeTracker(window.progressLogger);
            window.videoTime.suspend();
        }

        if (event.target.currentTime > 0) {
            dispatch(setVideoHasPlayed(true));
        } else {
            dispatch(setVideoHasPlayed(false));
            //dispatch(setOpen(true));
            // setTimeout(() => {
            //     if (event.target.currentTime <= 0) {
            //         event.target.poster = finalURI;
            //     }
            // }, 500);
        }
    }

    /**
     * 
     */
    const onEnded = () => {
        if (document.getElementById("media-wrapper")) {
            document.getElementById("media-wrapper")!.style.backgroundColor = "#000000";
        }
        /*
        const progress: any = {
            attempts: 1,
            completed: 1,
            successfulAttempts: 1,
            total: 1,
            percentComplete: 1,
            state: {}
        }
        */
        dispatch(setVideoHasPlayed(false));
        const progressTrack = new window.szReport.ProgressTracker(window.progressLogger);
        progressTrack.initialize(1);
        progressTrack.completed = 1;
        progressTrack.attempts = 1;
        progressTrack.successfulAttempts = 1;
        console.log(progressTrack, window.progressLogger, progressTrack.isTracking(), progressTrack.useLocalStorage());
        progressTrack.save();

        window.videoTime.suspend();
        window.showMediaOverlay();
    }

    const handleVideoClick = () => {
        if(areGameButtonsVisible){
            dispatch(hideGameButtons());
        } else {
            dispatch(showGameButtons());
        }
    }

    /**
     *
     *
     */
	return (
        <div className={`clearfix ${openingVideoElements.outerClass}`} id="video-wrapper" style={{background: "transparent"}}>
            <div className={`embed-responsive ${openingVideoElements.innerClass}`}>
                <video
                    style={{backgroundColor: videoBackgroundColor}}
                    onContextMenu={() => {return false;}}
                    className="embed-responsive-item"
                    // autoPlay // Let the useEffect handle the autoplay functionality
                    webkit-playsinline="true"
                    playsInline
                    id="sz-media-player"
                    ref={videoElementRef}
                    onDurationChange={(event) => { onDurationChange(event) }}
                    onPlay={(event) => { onPlay(event) }}
                    onTimeUpdate={(event) => { onTimeUpdate(event) }}
                    onPause={(event) => { onPause(event) }}
                    onEnded={() => { onEnded() }}
                    onClick={() => { handleVideoClick() }}
                >
                    { getVideo() }
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>
	);
}

export default VideoSongPlayer;
