/**
 * ## Pause Dialog Slice
 * 
 * @category Slice
 * @module Pause Dialog Slice
 */
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from '@reduxjs/toolkit';

interface IPauseState {
	isPaused: boolean;
    isMusicPlaying: boolean;
    isSpeakerPlaying: boolean;
}

const initialState: IPauseState = {
	isPaused: false,
    isMusicPlaying: true,
    isSpeakerPlaying: true
}

const pauseDialogSlice = createSlice({
	name: "pause",
	initialState,
	reducers: {
		setOpen: (state, action: PayloadAction<boolean>) => {
			state.isPaused = action.payload;
		},
        togglePause: (state) => {
            state.isPaused = !state.isPaused;
        },
        toggleIsMusicPlaying: (state) => {
            state.isMusicPlaying = !state.isMusicPlaying;
        },
        setIsMusicPlaying: (state,  action: PayloadAction<boolean>) => {
            state.isMusicPlaying = action.payload;
        },
        toggleIsSpeakerPlaying: (state) => {
            state.isSpeakerPlaying = !state.isSpeakerPlaying;
        }
	}
});

export const { setOpen, togglePause, toggleIsMusicPlaying, setIsMusicPlaying, toggleIsSpeakerPlaying } = pauseDialogSlice.actions;

export default pauseDialogSlice.reducer;
