/**
 * ## Error Page
 * 
 * Provides the react-router error page
 * 
 * @category Component
 * @module Error Page
 */

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { sendMessage } from "../lib/iFrameCommunications";

const ErrorPage = ( {
		error,
		resetErrorBoundary
	}: {
		error: Error,
		resetErrorBoundary: any
	} ) => {

	const headerStyle: React.CSSProperties = {
		height: "92px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "#ffffff"
	}

	const contentStyle: React.CSSProperties = {
		flex: 1,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		paddingTop: "20px",
		backgroundColor: "#1b6eb7"
	}
	
	const handleExitMiniPath = (e: any) => {
		sendMessage("CLOSE MINI");
    }

	return (
		<>
			<div className="error-page" style={{
					display: "flex",
					flexDirection: "column",
					minHeight: "100vh",
					backgroundColor: "#f5f5f5"
				}}>

				<header className="header" style={headerStyle}>
					<img
						id="at-logo"
						src="images/logo/anywhere-teacher-256x256.png" 
						style={{
							position: "absolute",
							top: "30px",
							width: "92px",
							height: "92px",
							zIndex: 1000,
							cursor: "pointer"
						}}
						alt="click to go back to home page"
						onClick={() => {}}
					/>
				</header>

				<div className="error-content" style={contentStyle}>
					
					<Container maxWidth="lg">
						<Box
							display="flex" 
							alignItems="center"
							justifyContent="center"
						>
							<Typography variant="h4" component="h2" gutterBottom style={{
								fontFamily: "kg_blank_space_solid",
								fontSize: 32,
								color: "#ffffff"
							}}>
								Oops!
							</Typography>
						</Box>
						<Box
							display="flex" 
							alignItems="center"
							justifyContent="center"
						>	
							<Typography variant="h4" component="h2" gutterBottom style={{
								fontFamily: "kg_blank_space_solid",
								fontSize: 24,
								color: "#ffffff",
								textAlign: "center",
								marginBottom: "50px"
							}}>
								Sorry, an unexpected error has occurred.
							</Typography>
						</Box>
						<Box
							display="flex" 
							alignItems="center"
							justifyContent="center"
						>		
							<button
								style={{
									backgroundColor: "#14538B",
									color: "#ffffff",
									padding: "10px 20px",
									border: "none",
									borderRadius: "8px",
									display: "flex",
									alignItems: "center",
									cursor: "pointer",
									fontFamily: "kg_blank_space_solid",
									fontSize: "16px"
								}} 
								onClick={handleExitMiniPath}
							>
									<i className="fa fa-arrow-left" style={{fontSize: 24, paddingRight: "10px"}}/>
									Go back home
							</button>
							
						</Box>
					</Container>

				</div>
			</div>

			
		</>
	);
}

export default ErrorPage;
