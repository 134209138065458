/**
 * ## Override the Framework SZ Button Class 
 * 
 * Once the framework is loaded, as indicated by the script load event firing, override the SZButtons class
 * and replace it with our implementation.  
 * 
 * In the case of **songs and videos**, we want to override the SZButtons class and replace with void functions.  
 * 
 * @category Helpers
 * @module Activity Utilities
 */

import { ActivityType } from "../../../components/ActivityUI";
import { store } from "../../../app/store"
import { setAreGameButtonsCollapsed } from "../../../slices/miniSlice";
import { mapUrl } from "../../../lib/urlTools";
import { AllProgress, updatePathDataProgress } from "../../../slices/activitySlice";

/**
 * ### SZ Buttons Override types  
 */
export interface ISZButtonSettings {
    trayWidth: number;
    buttonFontSize: number;
    buttonWidth: number;
    buttonHeight: number;
    buttonSpacing: number;
}

/**
 * Data settings for control of animation of the HTML SZ Button tray
 */
export interface ISZButton {
    collapsed: boolean;
    settings: Array<ISZButtonSettings>;
}

/**
 * Description of button sizes supported
 */
export enum ButtonSize {
    Big = 0,
    Small = 1
}

declare global {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface Window {
        szButtons: {
            data: ISZButton
        };
        szProgressTracker: any,
        jQuery: any
	}
}

/**
 * ## Override the SZButtons Class
 *   
 * override the start, show, hide, and center methods for the SZ Buttons  
 *   
 * if activity type is **song/video** then complete stub off all of the methods as  
 * the video player has no dependency on AT.  
 *   
 * @param activityType              activity type
 */
export const overrideSZButtons = (activityType: ActivityType) => {
    let areGameButtonsCollapsed = store.getState().mini.areGameButtonsCollapsed;

    if(!window.hasOwnProperty("SZButtons")){
        window.SZButtons = function () {
            if (document.querySelector('.sz-game-buttons')) {
                this.data = {
                    collapsed: false,
                    big: {
                        trayWidth: 4 + (document.querySelector('#sz-game-inside-buttons')!?.children.length - 1) * 60,
                        buttonFontSize: '28px',
                        buttonWidth: '56px',
                        buttonHeight: '56px',
                        buttonPadding: '6px 12px',
                        buttonMargin: '4px'
                    },
                    small: {
                        trayWidth: 4 + (document.querySelector('#sz-game-inside-buttons')!?.children.length - 1) * 52,
                        buttonFontSize: '24px',
                        buttonWidth: '48px',
                        buttonHeight: '48px',
                        buttonPadding: '6px 12px',
                        buttonMargin: '4px'
                    }
                };
                document.querySelector('.game-toggle-tray-button')!?.addEventListener("click", (this.show.bind(this)));
                window.szGame.game.state.onStateChange.add(function () {
                    window.szGame.game.state.states[window.szGame.game.state.current].game.input.onDown.add(window.SZButtons.hide.bind(window.SZButtons));
                }, this);
            }
        };
    }

    // Config for SZ Button tray
    window.szButtons = {
        data: {
            collapsed: areGameButtonsCollapsed,
            settings: [
                {
                    // Big settings
                    trayWidth: 0,
                    buttonFontSize: 28,
                    buttonWidth: 56,
                    buttonHeight: 56,
                    buttonSpacing: 4
                },
                {
                    // Small settings
                    trayWidth: 0,
                    buttonFontSize: 20,
                    buttonWidth: 42,
                    buttonHeight: 42,
                    buttonSpacing: 4
                }
            ]
        }
    };

    // Determine whether to use big or small buttons settings based on breakpoint
    // Calculate the tray width depending on button size and count
    window.SZButtons.prototype.determineSettings = function () : ISZButtonSettings | undefined {
        // Determine whether to use big or small buttons settings
        let buttonSize: ButtonSize = (window.innerWidth < 768) ? ButtonSize.Small : ButtonSize.Big;

        if(window.szButtons.data) {
            let settings = window.szButtons.data.settings[buttonSize];

            // Calculate tray width based on button count
            const element = document.querySelector("#sz-game-inside-buttons");
            if(element){
                let buttonCount = document.querySelector("#sz-game-inside-buttons")!.childNodes.length - 1;
                settings.trayWidth = settings.buttonSpacing + (buttonCount * settings.buttonWidth) + (buttonCount * settings.buttonSpacing);    
            }
            
            return settings;
        } else {
            return undefined;
        }
    }

    // Override SZButtons.start
    window.SZButtons.prototype.start = function () {
        // Reshow game buttons after activity transition
        if (document.getElementById("sz-buttons-wrapper")) {
            document.getElementById("sz-buttons-wrapper")!.style.display = "inline";
        }

        if (document.querySelector('.sz-game-buttons')) {
            window.SZButtons.prototype.center(false, true);
            document.querySelector(".sz-game-buttons")!.animate(
                [  
                    { opacity: 0 },
                    { opacity: 1 }
                ],
                {
                    easing: "ease-in-out",
                    iterations: 1,
                    duration: 500
                }
            );
            document.getElementById("sz-game-inside-buttons")!.style.opacity = "1";
        }
    };

    /**
     * Override SZButtons.center.
     * 
     * NOTE: sz-buttons-2020-02-05_1.2.5.js has a window.resize listener that calls SZButtons.center() on every window resize event
     * The fromMini parameter is used to determine when this function is called by the Mini app or by the legacy szButtons class
     */
    window.SZButtons.prototype.center = function(animate: boolean, fromMini: boolean) {
        if(!document.getElementById("mini-content")){
            // mini-content may not exist since SZButtons.center() is called on every window resize event
            return;
        }

        const shouldGameButtonsSlide = store.getState().mini.shouldGameButtonsSlide;

        // Use this window.resize listener to maintain szButton 'left' alignment
        if (!fromMini) {
            let settings = window.SZButtons.prototype.determineSettings();
            const gameButtons = document.querySelector('.sz-game-buttons') as HTMLElement;
            const miniContentWidth = document.getElementById("mini-content")!.clientWidth as number;
            const pathBaseWidth = document.getElementById("path-base")!.clientWidth as number;
            const centeredX = pathBaseWidth + ((miniContentWidth - pathBaseWidth) / 2) - (settings.trayWidth / 2);
            const hiddenX = miniContentWidth - settings.buttonWidth - (2 * settings.buttonSpacing);

            if(store.getState().mini.areGameButtonsCollapsed){
                gameButtons!.style.left = hiddenX + 'px';
            } else {
                gameButtons!.style.left = centeredX + "px";
            }
        } else {
            // SZButtons.center called from Mini app, perform animation

            // Toggle button starts hidden
            const element =  document.getElementById("game-toggle-tray-button");
            if(element){
                document.getElementById("game-toggle-tray-button")!.style.display = "none";
            }

            // Update button spacing
            let settings = window.SZButtons.prototype.determineSettings();
            const buttonElements: any = document.querySelectorAll("#sz-game-inside-buttons button");
            buttonElements.forEach((buttonElement: any) => {
                buttonElement.style.width = settings.buttonWidth + "px";
                buttonElement.style.height = settings.buttonHeight + "px";
                buttonElement.style.marginRight = settings.buttonSpacing + "px";
            });

            // Remove last button's margin right
            const buttonElement: any = document.querySelector("#sz-game-inside-buttons button:last-of-type")
            if(buttonElement){
                buttonElement.style.marginRight = "0px";
            }

            // Update font
            const buttonIcons: any = document.querySelectorAll(".sz-game-inside-buttons .fa")
            buttonIcons.forEach((buttonIcon: any) => {
                buttonIcon.style.fontSize = settings.buttonFontSize + "px";
            });

            // Calculate tray settings
            const miniContentWidth = document.getElementById("mini-content")!.clientWidth as number;
            const pathBaseWidth = document.getElementById("path-base")!.clientWidth as number;
            const centeredX = pathBaseWidth + ((miniContentWidth - pathBaseWidth) / 2) - (settings.trayWidth / 2);
            const hiddenX = miniContentWidth - settings.buttonWidth - (2 * settings.buttonSpacing);

            // Set tray width
            document.getElementById("sz-game-inside-buttons")!.style.width = settings.trayWidth + "px";

            if (store.getState().mini.areGameButtonsCollapsed) {
                document.getElementById("sz-game-inside-buttons")!.style.left = hiddenX + "px";
                // Hide toggle button
                document.getElementById("game-toggle-tray-button")!.style.display = "none";
            } else {
                if (animate && shouldGameButtonsSlide) {
                    document.querySelector(".sz-game-buttons")!.animate(
                        {
                            left: [hiddenX + "px", centeredX + "px"]
                        },
                        {
                            easing: "ease-in-out",
                            iterations: 1,
                            duration: 500
                        }
                    );
                }
                document.getElementById("sz-game-inside-buttons")!.style.left = centeredX + "px";
            }
        
            // Center the video timer (if applicable)
            let videoTimerElement = document.getElementById("video-timer");
            if(videoTimerElement) {
                let timerWidth = videoTimerElement!.clientWidth as number;
                let timerHeight = videoTimerElement!.clientHeight as number;
                
                const timerCenteredX = pathBaseWidth + ((miniContentWidth - pathBaseWidth) / 2) - (timerWidth / 2);
                videoTimerElement!.style.left = timerCenteredX + "px";

                let offsetTop = document.getElementById("sz-game-inside-buttons")?.offsetTop as number;
                let timerY = offsetTop - timerHeight - settings.buttonSpacing;
                videoTimerElement!.style.top = timerY + "px";
            }
        }
    }

    // Override SZButtons.hide
    window.SZButtons.prototype.hide = function() {
        const shouldGameButtonsSlide = store.getState().mini.shouldGameButtonsSlide;

        let settings = window.SZButtons.prototype.determineSettings();

        const miniContentWidth = document.getElementById("mini-content")!.clientWidth as number;
        const pathBaseWidth = document.getElementById("path-base")!.clientWidth as number;
        const centeredX = pathBaseWidth + ((miniContentWidth - pathBaseWidth) / 2) - (settings.trayWidth / 2);
        const hiddenX = miniContentWidth - settings.buttonWidth - (2 * settings.buttonSpacing);

        // Set tray width
        document.getElementById("sz-game-inside-buttons")!.style.width = settings.trayWidth + settings.buttonWidth + settings.buttonSpacing + "px";

        if (!store.getState().mini.areGameButtonsCollapsed) {
            store.dispatch(setAreGameButtonsCollapsed(true));
            window.szButtons.data.collapsed = true;

            // Show toggle '?' button
            let toggleButton = document.getElementById("game-toggle-tray-button");
            toggleButton!.style.display = "block";
            toggleButton!.style.width =  settings.buttonWidth + "px";
            toggleButton!.style.height =  settings.buttonHeight + "px";
            // toggleButton!.style.marginLeft =  settings.buttonMargin + "px";
            toggleButton!.style.marginTop =  "0px";

            // Update font
            const buttonIcons: any = document.querySelectorAll(".sz-game-buttons .fa")
            buttonIcons.forEach((buttonIcon: any) => {
                buttonIcon.style.fontSize = settings.buttonFontSize + "px";
            });

            if(shouldGameButtonsSlide){
                document.querySelector(".sz-game-buttons")!.animate(
                    {
                        left: [centeredX + "px", hiddenX + "px"]
                    },
                    {
                        easing: "ease-in-out",
                        iterations: 1,
                        duration: 500
                    }
                );
                document.getElementById("sz-game-inside-buttons")!.style.left = hiddenX + "px";
            } else {
                document.getElementById("sz-game-inside-buttons")!.style.left = hiddenX + "px";
            }
        }
    }

    // Override SZButtons.show
    window.SZButtons.prototype.show = function () {
        if (store.getState().mini.areGameButtonsCollapsed) {
            store.dispatch(setAreGameButtonsCollapsed(false));
            window.szButtons.data.collapsed = false;
            window.SZButtons.prototype.center(true, true);

            // Hide toggle '?' button
            document.getElementById("game-toggle-tray-button")!.style.display = "none";
        }
    }
}

/**
 * ## Mutation Observer to watch Media Wrapper  
 * 
 * Watch for style changes to media=wrapper that need top be moved to the body element 
 * to insure patterns underlap the path base image.
 * 
 * @param activityJSON              JSON data including javascript files to load and full node data for the current activity
 */
export const createMutationObserver = (activityJSON: any) => {
    const observerNode: any = document.getElementById("media-wrapper");
    const config = { attributes: true, childList: false, subtree: false };

    const callback = (mutationList: any, observer: any) => {
        for (const mutation of mutationList) {
            if (mutation.type === "attributes") {
                setBackgroundColor(activityJSON);
            }
        }
    };

    window.miniObserver = new MutationObserver(callback);
    window.miniObserver.observe(observerNode, config);
}
/**
 * ## Move the Media Wrapper Background
 * 
 * if the media-wrapper element is present   
 * + move the background style to the body tag  
 * + set media-wrapper background style to transparent to elimate pattern mismatches
 * 
 * @param activityJSON              JSON data including javascript files to load and full node data for the current activity
 */
export const setBackgroundColor = (activityJSON: any) => {    
    if (document.getElementById("media-wrapper")) {
        const mediaWrapper: any = document.getElementById("media-wrapper");

        if (mediaWrapper.style.background !== "transparent" && mediaWrapper.style.background !== "none") {
            if (mediaWrapper.style.background !== "rgb(0, 0, 0)") {
                document.body.style.background = mediaWrapper.style.background;
            } else if (activityJSON.data.node.field_background_color?.und?.length > 0) {
                document.body.style.background = activityJSON.data.node.field_background_color.und[0].rgb;
            } else {
                document.body.style.background = "rgb(0, 0, 0)";
            }
        }
        mediaWrapper.style.background = "transparent";
    }

    // Move the activity's color from 'media-inner' to 'mini-base'
    const mediaInner = document.getElementById('media-inner');
    const miniBase = document.getElementById('mini-base');

    // Mitch's iPad is showing mediaInner.style.background = "" therefore there are no styles to move to miniBase
    if(mediaInner && miniBase && mediaInner.style.background !== "transparent" && mediaInner.style.background !== "none") {
        // Moves bg color from mediaInner -> miniBase
        const background = mediaInner.style.background;
        if(background){
            miniBase.style.background = background;
            mediaInner.style.background = "transparent";
        }
    }
}

/**
 * Override to prevent AT force orientaion overlay. Let the mini path react component handle this
 * AT2-332 Fix
 */
export const overrideOrientationOverlay = () => {
    window.szForceOrientation = () => { }
}

/**
 * Override SZ Reporting (partial)
 * 
 * override some methods to enable szReporting to function without having to change the framework and
 * support both the beta and live mini URLs
 */
export const overrideReporting = () => {
    window.szIsDevelopmentHost = () => {
        var hostName = (window.location) ? window.location.hostname.toLowerCase() : "";

        // any domain other than "anywhereteacher.com" is considered a development host
        if ((hostName.indexOf("anywhereteacher.com") === 0) 
        || hostName.indexOf("beta.anywhereteacher.com") === 0 
        || (hostName.indexOf("dev.mini.anywhereteacher.com") === 0)  
        || (hostName.indexOf("mini.anywhereteacher.com") === 0)) {
            return false;
        }
      
        return true; 
    }

    window.szIsProductionHost = () => {
        var hostName = (window.location) ? window.location.hostname.toLowerCase() : "";

        return (hostName.indexOf("anywhereteacher.com") === 0 || hostName.indexOf("mini.anywhereteacher.com") === 0);
    }

    window.szGetMicroserviceUrl = (baseUrl: string, apiPath: string, stage: string | undefined) => {
        if (!stage) {
            // determine the stage via hostname
            var hostName = (window.location) ? window.location.hostname.toLowerCase() : "";
            // are we running from the beta site?
            if (hostName.indexOf("beta.anywhereteacher.com") === 0 || hostName.indexOf("dev.mini.anywhereteacher.com") === 0) {
                stage = "beta";
            }
            // are we running from the production/live site
            else if (hostName.indexOf("anywhereteacher.com") === 0 || hostName.indexOf("mini.anywhereteacher.com") === 0) {
                stage = "production";
            }
            // are we running on a local server and running a local version of the microservice? If we are, there are no stages
            else if ( ((hostName.indexOf("localhost") === 0) || (hostName.indexOf("127.0.0.1") === 0)) && (baseUrl.indexOf("localhost") >= 0) ) {
                // do not set stage 
                stage = undefined;
            }
            else {
                // all other sites are considered development
                stage = "development";
            }
        }

        // prepare base url for appending
        var url = baseUrl;
        if (url[url.length - 1] !=="/") {
            url += "/";
        }

        // add stage to url
        if (stage && stage.length) {
            url += stage + "/";
        }

        // add api path to url
        if (apiPath && apiPath.length) {

            if (apiPath[0] === '/') {
                url += apiPath.substr(1, apiPath.length - 1);
            }
            else {
                url += apiPath;
            }
        }

        return url;
    }
}

export const overrideVideoPlayer = function() {

    if(window.SZ?.VideoPlayer) {
        window.SZ.VideoPlayer.prototype.play = function() {
            let videoElement: HTMLVideoElement | null;
            if (!document.querySelector("video#sz-video-player")) {
                this.createVideoHtml();  
            } else {
                return;
            }
            
            // Show video element
            videoElement = document.querySelector("video#sz-video-player");
            this._videoElement = videoElement;
            videoElement!.style.display = "block";
    
            // Update poster url (AT2-345: Added for Safari auto play permission)
            let newPosterURL = mapUrl() + new URL(videoElement!.poster).pathname;
            videoElement!.poster = newPosterURL;
    
            // start playing video
            this.startPlaying();
        };

        window.SZ.VideoPlayer.prototype.addPlayButton = function() {
            const playButton = document.createElement("button");
            playButton.setAttribute("id", "sz-phaser-video-play-button");

            playButton.style.position = "absolute";
            playButton.style.display = "block";
            playButton.style.zIndex = "5";
            playButton.style.background = "none";
            playButton.style.border = "none";
            playButton.style.outline = "none";

            // Updated relative url to minipath url
            playButton.innerHTML = '<img src="' + mapUrl() + '/sites/default/files/icons/up-next-dialog-continue-button-216-fs8.png" style="width: 33%;" />';

            playButton.style.left = this._videoElement.style.left;
            playButton.style.top = this._videoElement.style.top;
            playButton.style.width = this._videoElement.style.width;
            playButton.style.height = this._videoElement.style.height;

            playButton.addEventListener("click", () => {
                this._videoElement.play();
                this.onStartPlaying();
                this._phaserWrapper.removeChild(playButton);
            });

            this._phaserWrapper.appendChild(playButton);
        };
    } 

}

/**
 * Override SZ Progress.save
 */
export const overrideProgressSave = () => {
    if(!window.szProgressTracker.oldSave) {
        // Save the original save method 
        window.szProgressTracker.oldSave = window.szProgressTracker.save;

        // Override the save method so mini project can detect "onSave" events
        window.szProgressTracker.save = () => {
            // Call the old save as normal
            window.szProgressTracker.oldSave();

            // Use setTimout so oldSave can complete
            setTimeout(() => {
                // Hit save endpoint
                let activityId = store.getState().activity.activityId;
                let pathId = store.getState().activity.parent.settings.szLearningPathId;
                let uid = store.getState().activity.parent.settings.sz_profiles.szPhaserProfile.uid;
                let pid = store.getState().activity.parent.settings.sz_profiles.szPhaserProfile.active.pid;
                let savePath = mapUrl() + "/adventure/save/activity/" + activityId + "/" + pathId + "/" + uid + "/" + pid;
                fetch (savePath)
                    .then(response => response.json())
                    .then(json => {
                        window.szLogger.log("%c Save Progress Fetch Complete:", "background-color: pink; color: black", json);

                        if(json && json.success){
                            // Update the redux store to match progress
                            let allProgress: AllProgress = [{
                                nid: "" + activityId,
                                attempts: "" + json.progress.attempts
                            }];
                            store.dispatch(updatePathDataProgress(allProgress));
                        }
                    })
                    .catch(error => {
                        window.szLogger.log("Error: Fetching Error", error);
                    });
            }, 1000)

        }
    }
}

export const createVideoPlayButton = (wrapper: HTMLElement | null, videoRef: React.RefObject<HTMLVideoElement>) => {
   // let videoWrapper = document.getElementById("video-wrapper");

    // Create play button shadow
    const playButtonShadow = document.createElement("div");
    playButtonShadow.id = "play-button-shadow"
    playButtonShadow.style.height = "100%";
    playButtonShadow.style.width = "100%";
    playButtonShadow.style.position = "absolute";
    playButtonShadow.style.top = "0px";
    playButtonShadow.style.background = "#00000099";

    // Create button
    const playButtonImg = document.createElement("img");
    playButtonImg.src = 'images/album-start.svg';
    playButtonImg.style.position = "absolute";
    playButtonImg.style.top = "50%";
    playButtonImg.style.left = "50%";
    playButtonImg.style.width = "50%";
    playButtonImg.style.height = "50%";
    playButtonImg.style.transform = "translate(-50%, -50%)"
    playButtonImg.style.background = "none";
    playButtonImg.style.border = "none";
    playButtonImg.style.outline = "none";

    // Add click listener
    playButtonImg.addEventListener("click", function() {
        videoRef.current!.play();
        wrapper!.removeChild(playButtonImg);
        wrapper!.removeChild(playButtonShadow);
    });

    wrapper!.appendChild(playButtonShadow);
    wrapper!.appendChild(playButtonImg);
}
