/**
 * ## Chevrons for Activities
 * 
 * Provides the left and right chevrons for use by games that may use  
 * them such as, books and flash cards.
 *   
 * @category Component
 * @module Chevron Navigation
 */
import { CSSProperties } from "react";
import { useAppSelector } from "../hooks/hooks";
import { IChevronProps, IButtonConfig, IButtonStyles } from "../types/types";

const Chevron = (props: IChevronProps) => {
    const isButtonBlocked = useAppSelector((state) => state.button.isButtonBlocked);

    const buttonStyles: IButtonStyles = {
        class1: {
            fill: "none",
            stroke: "#FFFFFF",
            strokeMiterlimit: 10,
            strokeWidth: "14.13px"
        },
        class2: {
            fill: "#14538b"
        }
    }

    const buttonAspectRatio = props.buttonRenderHeight / props.buttonSourceHeight;
    const chevronConfig: IButtonConfig = {
        width: Math.trunc(props.buttonSourceWidth * buttonAspectRatio),
        height: props.buttonRenderHeight,
        fontSize: Math.round(props.buttonRenderHeight / 2)
    }

    const containerStyle: CSSProperties = {
            width: props.buttonConfig.width, 
            height:  props.buttonConfig.height,
            position: "absolute",
            top: props.buttonAbsolutePosition.top,
            left: props.buttonAbsolutePosition.left,
            cursor: props.clickable && !isButtonBlocked ? "pointer" : "not-allowed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            transform: props.direction === "up" ? "rotate(180deg)" : "",
            visibility: props.show ? "visible" : "hidden"
    }

    return (
        <div style={containerStyle} onClick={props.clickable && !isButtonBlocked ? props.handler : () => {}}  key={`svg-chevron-${props.id}`}>
            <svg xmlns="http://www.w3.org/2000/svg" width={chevronConfig.width} height={chevronConfig.height}  viewBox="0 0 95.7 67.38">
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Exports">
                        <path style={buttonStyles.class1}
                            d="M47.85,60.32a3.1,3.1,0,0,1-2.28-1L8,21.2a3.18,3.18,0,0,1-1-2.31,3.22,3.22,0,0,1,1-2.32L16.43,8A3.15,3.15,0,0,1,21,8l26.87,27.3L74.72,8a3.15,3.15,0,0,1,4.55,0l8.4,8.53a3.22,3.22,0,0,1,1,2.32,3.18,3.18,0,0,1-1,2.31L50.13,59.34A3.1,3.1,0,0,1,47.85,60.32Z" />
                        <path style={buttonStyles.class2}
                            d="M47.85,60.32a3.1,3.1,0,0,1-2.28-1L8,21.2a3.18,3.18,0,0,1-1-2.31,3.22,3.22,0,0,1,1-2.32L16.43,8A3.15,3.15,0,0,1,21,8l26.87,27.3L74.72,8a3.15,3.15,0,0,1,4.55,0l8.4,8.53a3.22,3.22,0,0,1,1,2.32,3.18,3.18,0,0,1-1,2.31L50.13,59.34A3.1,3.1,0,0,1,47.85,60.32Z" />
                    </g>
                </g>
            </svg>
        </div>
    )
}

export default Chevron;
